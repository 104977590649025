import React from "react";

import { BannerOld, DevotionsPlan } from "../../img";
import StaticPageLayout from "../../components/StaticPages/Layout";

const Devotions: React.FC = () => {
  return (
    <StaticPageLayout background={BannerOld} title={"Nabożeństwa"}>
      <div className="flex flex-col flex-start w-full gap-y-1">
      <p>Nabożeństwa w każdą niedzielę o godzinie 9.00 </p>
      <p>Spowiedź i Komunia Święta w pierwszą niedzielę miesiąca, w czasie</p>
      <p>Adwentu i Pasji zapraszamy do Stołu Pańskiego w każdą niedzielę.</p>
      <p>Tygodniowe nabożeństwa adwentowe - środa godz. 17.00</p>
      <p>Tygodniowe nabożeństwa pasyjne - piątek godz. 17.00</p>
      <p>SERDECZNIE ZAPRASZAMY!</p>
      </div>

      <div className="flex flex-col items-center w-full mx-auto gap-y-1">
        <h3 className="semiboldheader3">Spotkania stałe</h3>
        <img
          src={DevotionsPlan}
          alt="Plan nabożeństw"
          className="w-full max-w-[920px]"
        />
      </div>

      <div className="flex flex-col items-center w-full mx-auto gap-y-1 max-w-[756px]">
        <h3 className="semiboldheader3">Plan nabożeństw pasyjnych 2025</h3>
        <div className="w-full overflow-x-auto px-2" id="devotions-table-wrapper">
          <table className="table table-auto">
          <tr className="bg-gray-100">
            <th className="w-12">Data</th>
            <th className="w-24">Dzień tygodnia</th>
            <th className="w-6">Godz.</th>
            <th>Rodzaj spotkania</th>
            <th className="w-8">Miejsce spotkania</th>
            <th>Dodatkowe informacje</th>
          </tr>
          <tr>
            <td>09.03.2025</td>
            <td>Niedziela</td>
            <td>9.00</td>
            <td className="text-center">
              1. Niedziela pasyjna
              <br />
              Invocavit
            </td>
            <td>Kościół</td>
            <td>
              Spowiedź i komunia święta, 
              <br />
              ofiara na diakonię polską</td>
          </tr>
          <tr>
            <td>16.03.2025</td>
            <td>Niedziela</td>
            <td>9.00</td>
            <td className="text-center">
              2. Niedziela pasyjna
              <br />
              Reminiscere
            </td>
            <td>Kościół</td>
            <td>Spowiedź i komunia święta</td>
          </tr>
          <tr>
            <td>23.03.2025</td>
            <td>Niedziela</td>
            <td>9.00</td>
            <td className="text-center">
              3. Niedziela pasyjna
              <br />
              Oculi
              </td>
            <td>Kościół</td>
            <td>Spowiedź i komunia święta</td>
          </tr>
          <tr>
            <td>30.03.2025</td>
            <td>Niedziela</td>
            <td>9.00</td>
            <td className="text-center">
              4. Niedziela pasyjna
              <br />
              Laetare
              </td>
            <td>Kościół</td>
            <td>Spowiedź i komunia święta</td>
          </tr>
          <tr>
            <td>06.04.2025</td>
            <td>Niedziela</td>
            <td>9.00</td>
            <td className="text-center">
              5. Niedziela pasyjna
              <br />
              Judica
            </td>
            <td>Kościół</td>
            <td>Spowiedź i komunia święta</td>
          </tr>
          <tr>
            <td>11.04.2025</td>
            <td>Piątek</td>
            <td>17.00</td>
            <td className="text-center">
              Tygodniowe nabożeństwo pasyjne
            </td>
            <td>Kościół</td>
            <td>Spowiedź i komunia święta</td>
          </tr>
          <tr>
            <td>13.04.2025</td>
            <td>Niedziela</td>
            <td>9.00</td>
            <td className="text-center">
              6. Niedziela pasyjna
              <br />
              Palmowa
            </td>
            <td>Kościół</td>
            <td>Spowiedź i komunia święta</td>
          </tr>
          <tr>
            <td>17.04.2025</td>
            <td>Czwartek</td>
            <td>18.00</td>
            <td className="text-center">
              Wielki czwartek
              <br />
              - nabożeństwo
            </td>
            <td>Kościół</td>
            <td>
              Spowiedź i komunia święta
            </td>
          </tr>
          <tr>
            <td>17.04.2025</td>
            <td>Piątek</td>
            <td>9.00</td>
            <td className="text-center">
              Wielki piątek
              <br />
              - nabożeństwo
            </td>
            <td>Kościół</td>
            <td>
              Spowiedź, 
              komunia święta,
              ofiara, 
              skarbonki
            </td>
          </tr>
          <tr>
            <td>18.04.2025</td>
            <td>Piątek</td>
            <td>17.00</td>
            <td className="text-center">
              Wielki piątek
              <br />
              - nabożeństwo
            </td>
            <td>Kościół</td>
            <td>
              Spowiedź, 
              komunia święta,
              ofiara, 
              skarbonki
            </td>
          </tr>
          <tr>
            <td>20.04.2025</td>
            <td>Niedziela</td>
            <td>10.00</td>
            <td className="text-center">
              1. Dzień świąt zmartwychwstania Pańskiego
            </td>
            <td>Kościół</td>
            <td className="text-center">-</td>
          </tr>
          <tr>
            <td>21.04.2025</td>
            <td>Poniedziałek</td>
            <td>10.00</td>
            <td className="text-center">
              2. Dzień świąt zmartwychwstania Pańskiego
            </td>
            <td>Kościół <br />w Białej</td>
            <td>Wspólne śpiewanie</td>
          </tr>
          </table>
        </div>
      </div>
    </StaticPageLayout>
  );
};

export default Devotions;
