import React, { useEffect, useState, lazy } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import { Navbar, Footer } from "./components";

import Information from "./pages/static/Information";
import Callendar from "./pages/posts/Callendar";
import Graduale from "./pages/static/Graduale";
import Devotions from "./pages/static/Devotions";
import Offering from "./pages/static/Offering";
import Soar from "./pages/static/Soar";
import Senior from "./pages/static/Senior";

import History from "./pages/posts/History";
import Publications from "./pages/posts/Publications";
import News from "./pages/posts/News";

import LandingPage from "./pages/LandingPage";
import NotFound from "./pages/misc/NotFound";

// Admin pages
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/Admin/PrivateRoute";
import Dashboard from "./pages/admin/Dashboard";
import Login from "./pages/admin/Login";
import PinPosts from "./pages/admin/Actions/PinPosts";
import EventHandler from "./components/Admin/EventHandler";
import AdminDashboard from "./components/Admin/DashboardLayout";

// Heavy components
const PostDetail = lazy(() => import("./pages/PostDetail"));
const Posts = lazy(() => import("./pages/admin/Tables/Posts"));
const EditPost = lazy(() => import("./pages/admin/Actions/EditPost"));

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* ADMIN PAGES */}

        <Route
          path="/admin/*"
          element={
            <AuthProvider>
              {" "}
              <PrivateRoute />{" "}
            </AuthProvider>
          }
        >
          <Route path="login" Component={Login} />
          <Route path="*" element={<AdminAnimatedTransition />} />
        </Route>

        {/* USER PAGES */}
        <Route path="*" element={<PublicAnimatedTransition />} />
      </Routes>
    </Router>
  );
};

const PublicAnimatedTransition: React.FC = () => {
  const location = useLocation();
  const [nodeRefs, setNodeRefs] = useState(
    new Map<string, React.RefObject<HTMLDivElement>>()
  );

  useEffect(() => {
    if (!nodeRefs.has(location.key)) {
      setNodeRefs((prev) => new Map(prev).set(location.key, React.createRef()));
    }
  }, [location, nodeRefs]);

  const nodeRef = nodeRefs.get(location.key);

  return (
    <div className="flex flex-col justify-start w-full min-h-[100vh] overflow-x-hidden">
      <Navbar />
      <TransitionGroup className="w-full h-full my-auto">
        {nodeRef && (
          <CSSTransition
            key={location.key}
            timeout={300}
            classNames="fade"
            nodeRef={nodeRef}
          >
            <div ref={nodeRef} className="w-full h-full">
              <Routes location={location}>
                {/* STATIC PAGES */}
                <Route path="/" Component={LandingPage} />
                <Route path="/information" Component={Information} />
                <Route path="/news" Component={News} />
                <Route path="/calendar" Component={Callendar} />

                <Route path="/history" Component={History} />
                <Route path="/choir" Component={Graduale} />
                <Route path="/publications" Component={Publications} />
                <Route path="/devotions" Component={Devotions} />
                <Route path="/offerings" Component={Offering} />

                <Route path="/soar" Component={Soar} />
                <Route path="/senior" Component={Senior} />

                {/* DYNAMIC PAGES */}
                <Route path="post/:slug" Component={PostDetail} />

                {/* ERROR PAGES */}
                <Route path="/404-not-found" Component={NotFound} />
                <Route path="*" Component={NotFound} />
              </Routes>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      <Footer />
    </div>
  );
};

const AdminAnimatedTransition: React.FC = () => {
  const location = useLocation();
  const [nodeRefs, setNodeRefs] = useState(
    new Map<string, React.RefObject<HTMLDivElement>>()
  );

  useEffect(() => {
    if (!nodeRefs.has(location.key)) {
      setNodeRefs((prev) => new Map(prev).set(location.key, React.createRef()));
    }
  }, [location, nodeRefs]);

  const nodeRef = nodeRefs.get(location.key);

  return (
    <AdminDashboard>
     <TransitionGroup className="px-4 pb-8 w-full">
       {nodeRef && (
         <CSSTransition
           key={location.key}
           timeout={300}
           classNames="fade"
           nodeRef={nodeRef}
         >
           <Routes location={location}>
             <Route path="" element={<Navigate to="/admin/pages/dashboard"/>} />
             <Route path="pages/dashboard" Component={Dashboard} />
             <Route path="tables/posts" Component={Posts} />
             <Route path="actions/new_post" Component={EditPost}/>
             <Route path="actions/edit_post/:slug" Component={EditPost}/>
             <Route path="actions/pin_posts" Component={PinPosts}/>
             <Route path="*" element={<p>Not found</p>}/> {/* TODO: Create a 404 page*/}
           </Routes>
         </CSSTransition>
       )}
     </TransitionGroup>
     <EventHandler/>
    </AdminDashboard>
  );
};

export default App;
