import React from "react";
import StaticPageLayout from "../../components/StaticPages/Layout";

import { BannerChurch, Logo2, Proboszcz } from "../../img";
import { address, zipcode, phone_parish, phone_pastor, contact_email, bank_info} from "../../constants/Information";

const Information: React.FC = () => {
  return (
    <StaticPageLayout title="Informacje" background={BannerChurch}>
      <div
        id="InfoContainer"
        className="flex flex-col md:flex-row md:flex-wrap text-foreground justify-around gap-6 items-center"
      >
        <div className="flex flex-col lg:basis-[26%] gap-y-3">
          <div className="flex flex-row w-full justify-center">
            <img src={Logo2} alt="Logo" className="w-48 h-48" />
          </div>
          <div className="flex flex-col items-start gap-y-2">
            <div>
              <h3 className="boldbasetext">
                Parafia Ewangelicko-Augsburska Bielsko
              </h3>
              <address className="basetext">
                <a href="https://maps.app.goo.gl/NhPcUi1VkXVpt7dN8">
                  <span className="clickable">{address}</span>
                  <br />
                  {zipcode}
                </a>
                <br />
                Telefon: &emsp;
                <a href="tel:+48338227471" className="clickable">
                  {phone_parish}
                </a>
                &emsp;
                <a href="tel:+48519048551" className="clickable">
                  {phone_pastor}
                </a>
                <br />
                E-mail:&emsp;
                <a href="mailto:bielsko@luteranie.pl" className="clickable">
                  {contact_email}
                </a>
                <br />
              </address>
            </div>

            <p>
              <span className="boldbasetext">
                Kancelaria Parafialna - czynna:
              </span>
            </p>
            <table className="border-separate border-spacing-x-3">
              <tbody>
                <tr>
                    <td>Poniedziałek:</td>
                    <td>9.00 - 15.00</td>
                </tr>
                <tr>
                    <td>Wtorek:</td>
                    <td>9.00 - 15.00</td>
                </tr>
                <tr>
                    <td>Środa:</td>
                    <td>9.00 - 15.00</td>
                </tr>
                <tr>
                    <td>Czwartek:</td>
                    <td>9.00 - 17.00</td>
                </tr>
                <tr>
                    <td>Piątek:</td>
                    <td className="boldbasetext">Kancelaria nieczynna</td>
                </tr>
                <tr>
                    <td>Niedziela:</td>
                    <td>
                    8.30 - 9.00;
                    <br />
                    <span className="italic">(i po nabożeństwie)</span>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col lg:basis-[26%] gap-y-3 text-foreground">
          <div className="flex flex-col gap-y-1">
            <p><span className="boldbasetext">NIP</span>: {bank_info.nip}</p>
            <p><span className="boldbasetext">REGON:</span> {bank_info.region}</p>
          </div>
          <div className="flex flex-col gap-y-2">
          <p className="boldbasetext">Numery kont:</p>
          {Object.keys(bank_info.accounts).map((key, index) => (
            <div className="flex flex-col gap-y-1" key={index}>
                <p className="boldbasetext">{key}</p>
                <p className="basetext">{bank_info.accounts[key]}</p>
            </div>
          ))}
          </div>
        </div>
        <div className="flex flex-col lg:basis-[26%] gap-y-3">
          <h3 className="boldbasetext">Ks. Krzysztof Cienciała (proboszcz)</h3>
          <div className="flex flex-row w-full justify-center">
            <img
              src={Proboszcz}
              alt="Ks. Krzysztof Cienciała"
              className="w-48 h-48"
            />
          </div>
          <p>
            ks. Krzysztof Cienciała (proboszcz)
            <br />
            tel.{" "}
            <a href="tel:+48519048552" className="boldbasetext clickable">
              519 048 552
            </a>
            <br />
          </p>
        </div>
      </div>
    </StaticPageLayout>
  );
};

export default Information;
