import React, { useEffect, useState } from "react";

import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { AdminEventsList } from "../../constants/AdminEvents";

const EventHandler: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("info");
    const [isLoading, setIsLoading] = useState(false);

    const ToggleSnackbar = (message: string, severity: AlertColor) => {
        setOpen(true);
        setMessage(message);
        setSeverity(severity);
    }

  const AdminEvents: { [key: typeof AdminEventsList[number] ]: (event: Event) => void} = {
    "isLoading": (event: Event) => {
      const result = event as CustomEvent<{ value: boolean }>;
      setIsLoading(result.detail.value);
    },
    "editorImageUploadResult": (event: Event) => {
      const result = event as CustomEvent<{ value: boolean }>;
      if (result.detail.value) {
        
        ToggleSnackbar("The image was succesfully uploaded", "success");
      } else {
        ToggleSnackbar("The image upload failed", "error");
      }
    },
    "postsStateChange": (event: Event) => {
      const result = event as CustomEvent<{ value: boolean }>;
      if (result.detail.value) {
        ToggleSnackbar("The post state was succesfully changed", "success");
      } else {
        ToggleSnackbar("The post state change failed", "error");
      }
    },
    "postsTagsChange": (event: Event) => {
      const result = event as CustomEvent<{ value: boolean }>;
      if (result.detail.value) {
        ToggleSnackbar("The post tags were succesfully changed", "success");
      } else {
        ToggleSnackbar("The post tags change failed", "error");
      }
    },
    "postsDelete": (event: Event) => {
      const result = event as CustomEvent<{ value: boolean }>;
      if (result.detail.value) {
        ToggleSnackbar("The post was sucesfully deleted", "success");
      } else {
        ToggleSnackbar("Failed to delete post", "error");
      }
    },
    "editorUpdatePost": (event: Event) => {
      const result = event as CustomEvent<{ value: boolean }>;
      if (result.detail.value) {
        ToggleSnackbar("The post was succesfully updated", "success");
      } else {
        ToggleSnackbar("The post update failed", "error");
      }
    },
    "editorCreatePost": (event: Event) => {
      const result = event as CustomEvent<{ value: boolean }>;
      if (result.detail.value) {
        ToggleSnackbar("The post was succesfully created", "success");
      } else {
        ToggleSnackbar("The post creation failed", "error");
      }
    },
    "postsPinChange": (event: Event) => {
      const result = event as CustomEvent<{ value: boolean }>;
      if (result.detail.value) {
        ToggleSnackbar("Pinned posts succesfully updated", "success");
      } else {
        ToggleSnackbar("The post pinning failed", "error");
      }
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    Object.keys(AdminEvents).forEach((key: string) => {
      window.addEventListener(key, AdminEvents[key]);
    });

    return () => {
      Object.keys(AdminEvents).forEach((key) => {
        window.removeEventListener(key, AdminEvents[key]);
      });
    };
  });

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity as AlertColor}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default EventHandler;
