import React from "react";
import { Link } from "react-router-dom";

import { FooterBanner, Logo2, Facebook, Youtube } from "../img";
import {
  address,
  zipcode,
  phone_parish,
  contact_email,
  facebook_link,
  youtube_link,
} from "../constants/Information";
import Spacer from "./ui/Spacer";

const Footer: React.FC = () => {
  return (
    <footer
      className="relative w-full mt-auto bg-cover"
      style={{
        backgroundImage: `url(${FooterBanner})`,
        backgroundPosition: "center",
      }}
      aria-label="Stopka"
    >
      <div className="absolute backgroundImageFilterLight"/>
      <div className="md:hidden absolute backgroundImageFilterMobile"/>
      <Spacer variant="WhiteDown" />
      <div
        id="FooterContent"
        className="relative px-8 md:px-[11.25%] flex flex-col w-full lg:flex-row lg:justify-between lg:items-start gap-y-8 py-8 pb-12 md:pb-8 basetext text-foreground"
        aria-label="footer-content"
      >
        <div
          id="FooterLogotype"
          className="flex flex-col gap-y-4 p-4 items-center bg-purple-50 border-2 border-purple-500 rounded-2xl self-center lg:self-start"
          aria-label="Logotyp"
        >
          <img
            src={Logo2}
            alt="Logo parafii Ewangelicko-Augsburskiej w Bielsku"
            className=" object-cover w-48 h-48 md:w-32 md:h-32"
          />
          <p className="text-foreground semiboldheader3">
            Parafia
            <br />
            Ewangelicko-Augsburska
            <br />Bielsko
          </p>
        </div>
        <div
          id="FooterSocials"
          className="flex flex-col gap-y-4 p-4 bg-purple-50 border-2 border-purple-500 rounded-2xl self-center lg:self-start"
          aria-label="Social Media"
        >
          <p
            className="text-foreground semiboldheader3"
            aria-label="socialmedia-title"
          >
            Znajdź nas na:
          </p>
          <Link
            className="flex flex-row gap-x-2 items-center"
            to={facebook_link}
            aria-label="facebook-link"
            role="link"
          >
            <Facebook className="w-8 h-8" aria-label="facebook-logo" />
            <p className="basetext clickable">Facebook</p>
          </Link>
          <Link
            className="flex flex-row gap-x-2 items-center"
            to={youtube_link}
            aria-label="youtube-link"
            role="link"
          >
            <Youtube className="w-8 h-8" aria-label="youtube-logo" />
            <p className="basetext clickable">Youtube</p>
          </Link>
        </div>
        <div
          id="FooterContact"
          className="flex flex-col gap-y-4 p-4 bg-purple-50 border-2 border-purple-500 rounded-2xl self-center lg:self-start"
          aria-label="Kontakt"
        >
          <p
            className="text-foreground semiboldheader3"
            aria-label="contact-title"
          >
            Kontakt:
          </p>
          <address className="flex flex-col gap-y-2 basetext text-foreground not-italic">
            <p>Parafia Ewangelicko-Augsburska Bielsko</p>
            <a
              href="https://maps.app.goo.gl/NhPcUi1VkXVpt7dN8"
              className="clickable"
              aria-label="Adres"
              tabIndex={-1}
              onKeyDown={(e) =>
                e.key === "Enter" &&
                window.open("https://maps.app.goo.gl/NhPcUi1VkXVpt7dN8")
              }
            >
              {address}
            </a>
            <p aria-label="Kod pocztowy">{zipcode}</p>
            <p aria-label="Telefon kontaktowy">
              Telefon:{" "}
              <a href="tel:+48338227471" className="clickable" tabIndex={-1}>
                {phone_parish}
              </a>
            </p>
            <p aria-label="Email">
              E-mail:{" "}
              <a
                href={`mailto:${contact_email}`}
                className="clickable"
                tabIndex={-1}
              >
                {contact_email}
              </a>
            </p>
          </address>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
