import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { Logo, MenuIcon, CloseIcon, ChevronDownIcon } from "../img";
import { NavigationLinks } from "../constants";

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <nav className="sticky top-0 w-full overflow-visible h-auto z-10 bg-background navbarlinks text-foreground shadow-md select-none drag" aria-label="Nawigacja">
      <div className="px-8 md:px-[11.25%] flex flex-row justify-between p-6 pt-8 md:p-8  md:pt-8 items-center overflow-visible">
        <Link to="/" aria-label="Strona główna" className="flex flex-row items-center gap-x-3 pr-2">
          <Logo className="w-16 h-16 md:w-24 md:h-24" aria-label="Logotyp"/>
          <p className="hidden xl:block semiboldheader2 text-purple-500">
            Parafia Ewangelicko-Augsburska Bielsko
          </p>
          <p className="block sm:block xl:hidden semiboldheader2 text-purple-500">
            Parafia Bielsko
          </p>
        </Link>
        <MenuIcon
          className="lg:hidden max-w-12 max-h-12"
          color="text-primary"
          onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
        />
        <div className="hidden lg:flex flex-row gap-x-8 navbarlinks">
          {NavigationLinks.map((elem, index) => (
            elem.links ? (
                <CollabsibleLabel label={elem.name} key={index}>
                  {elem.links.map((subelem, subindex) => (
                    <Link
                      key={subindex}
                      to={subelem.path}
                      onClick={() => setIsMenuOpen(false)}
                      className="basetext text-foreground navbarlinks opacity-70 hover:opacity-100"
                    >
                      {subelem.name}
                    </Link>
                  ))}
                </CollabsibleLabel>
              ) : (
                <Link
                  className="text-foreground navbarlinks"
                  key={index}
                  to={elem.path}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {elem.name}
                </Link>
              )
          ))}
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`${
          isMenuOpen ? "fixed" : "hidden"
        } w-full h-full bg-opacity-50 bg-foreground z-50 top-0 left-0`}
      >
        <div className="absolute w-[50%] h-full right-0 bg-background">
          <div className="flex flex-col gap-9">
            <div className="flex flex-row justify-between items-center px-8 py-4 border-b-2 border-purple-500">
              <h2 className="semiboldheader3">Menu</h2>
              <CloseIcon
                className="w-4 h-4 text-foreground"
                onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
              />
            </div>
            <div className="flex flex-col gap-y-8 justify-center items-center navbarlinks text-foreground">
              {NavigationLinks.map((elem, index) => (
                  elem.links ? (
                    <CollabsibleLabel label={elem.name} key={index}>
                      {elem.links.map((subelem, subindex) => (
                        <Link
                          key={subindex}
                          to={subelem.path}
                          onClick={() => setIsMenuOpen(false)}
                          className="text-foreground navbarlinks opacity-70 hover:opacity-100"
                        >
                          {subelem.name}
                        </Link>
                      ))}
                    </CollabsibleLabel>
                  ) : (
                    <Link
                      className="text-foreground navbarlinks"
                      key={index}
                      to={elem.path}
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {elem.name}
                    </Link>
                  )
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const CollabsibleLabel: React.FC<{
  label: string;
  children: React.ReactNode;
}> = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const button = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!button.current?.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  });

  return (
    <div
      className="relative justify-center items-center overflow-y-visible"
      onClick={() => setIsOpen((isOpen) => !isOpen)}
      ref={button}
      aria-expanded={isOpen} 
      aria-controls={`${label}-menu`}
      onKeyDown={(e) => e.key === "Enter" && setIsOpen((isOpen) => !isOpen)}
      role="button"
      tabIndex={0}
    >
      <div className="flex flex-row gap-x-2 justify-center items-center">
        <p className="text-foreground navbarlinks" aria-label={`${label}-menu`}>{label}</p>
        <ChevronDownIcon
          className={`w-4 h-4 transform transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
          color="text-foreground"
          aria-label="chevron-down"
        />
      </div>

      <div
        className={`${
          isOpen ? "flex lg:absolute" : "hidden"
        } mt-4 md:mt-2 z-20 left-0 p-4 bg-background border-1 rounded-md border-2 border-purple-500`}
      >
        <div className="flex flex-col gap-y-4 md:gap-y-2 justify-center min-w-16 max-w-32 shrink-1">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
