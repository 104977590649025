import React, { useState } from "react";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  Download,
  Fullscreen,
  Thumbnails,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/thumbnails.css";

interface ImageGalleryProps {
  images: string[];
  className?: string;
  setIndex?: (index: number) => void;
  startIndex?: number;
}

const ImageGallery: React.FC<ImageGalleryProps> = (
  props: ImageGalleryProps
) => {
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const setIndex = props.setIndex || setCurrentIndex;

  return (
    <>
      <div
        className={`flex flex-wrap w-full gap-4 items-center justify-around ${props.className}`}
      >
        {props.images.map((image, index) => (
          <div
            className="min-h-16 min-w-16 max-w-96 max-h-96 grow w-full h-20rem basis-1/2 md:basis-1/3 lg:basis-1/5"
            key={props.startIndex ? index + props.startIndex : index}
          >
            <img
              src={image}
              className="object-cover cursor-pointer w-full h-full rounded-xl"
              style={{ maxHeight: "inherit" }}
              alt={`galleryImage-${index}`}
              onClick={() => setIndex(index)}
            />
          </div>
        ))}
      </div>
      {!props.setIndex && (
        <Lightbox
          plugins={[Download, Fullscreen, Zoom, Thumbnails]}
          index={currentIndex}
          open={currentIndex >= 0}
          close={() => setCurrentIndex(-1)}
          slides={props.images.map((image) => ({ src: image }))}
        />
      )}
    </>
  );
};

export default ImageGallery;
