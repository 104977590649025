import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  DataGrid,
  GridRowId,
  GridRowParams,
  type GridColDef,
  GridRowModesModel,
  GridRenderEditCellParams,
  GridInitialState,
} from "@mui/x-data-grid";
import { format } from "date-fns";

import { PostTagType, PostType } from "../../../types/Post";
import {
  getPosts,
  getTags,
  patchPostTags,
  patchPostState,
  deletePost,
} from "../../../services/adminPostService";
import { CustomTagsEditorCell, CustomTagsRenderCell } from "./TagsEditor";
import { CustomStateRenderCell } from "./StateRender";
import { DefaultDataGridActions } from "./Actions";

interface PostRowsDataType extends PostType {
  isFacebookPost: boolean;
  isEvent: boolean;
  "Created at": Date;
  "Updated at": Date | null;
}

type DataGridCustomAction<T = (...args: any[]) => void> = {
  actionProps: T;
  action: (params: GridRowParams, actionProps: T) => void;
};

type PostGridProps = {
  data?: PostType[];
  actions?: DataGridCustomAction[];
  editable?: boolean;
  initialState?: GridInitialState;
};

const PostsGrid: React.FC<PostGridProps> = (props: PostGridProps) => {
  const editable = props.editable ?? true;

  const [posts, setPosts] = useState<PostType[]>([]);
  const [tags, setTags] = useState<PostTagType[]>([]);
  const [rows, setRows] = useState<PostRowsDataType[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const navigate = useNavigate();

  // Fetch tags
  useEffect(() => {
    const fetchTags = async () => {
      const tags = await getTags();
      setTags(tags);
    };
    fetchTags();
  }, []);

  // Fetch posts or update posts if the data is passed as a prop
  useEffect(() => {
    const fetchData = async () => {
      const data = await getPosts();
      setPosts(data);
      setRows(transformData(data));
    };
    if (props.data) {
      setPosts(props.data);
      setRows(transformData(props.data));
    } else {
      fetchData();
    }
  }, [props.data]);

  const sendEvent = (value: boolean, eventName: string) => {
    const event = new CustomEvent(eventName, {
      detail: { value },
    });
    window.dispatchEvent(event);
  };

  const transformData = (data: PostType[]): PostRowsDataType[] =>
    data.map((post) => ({
      ...post,
      "Created at": new Date(post.created_at),
      "Updated at": post.updated_at ? new Date(post.updated_at) : null,
      isFacebookPost: post.facebook !== null,
      isEvent: post.event !== null,
    }));

  const handleEditPost = useCallback(
    (slug: string) => {
      navigate(`/admin/actions/edit_post/${slug}`);
    },
    [posts, rows]
  );

  const handleDeletePost = useCallback(
    async (id: GridRowId) => {
      const post = rows.find((row) => row.id === id);
      console.log(id);
      console.log(post);
      if (!post) 
      {
        sendEvent(false, "postsDelete");
        return;
      };
      await deletePost(post.slug).then((result) => {
        if (result) {
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
          sendEvent(true, "postsDelete");
        } else {
          sendEvent(false, "postsDelete");
        }
      });
  }, [posts, rows]);

  const handleStateChange = useCallback(
    async (id: GridRowId, state: "published" | "draft" | "hidden") => {
      const post = rows.find((row) => row.id === id);
      if (!post) return false;

      try {
        const result = await patchPostState(post.slug, state);
        if (!result) {
          setRows((prevRows) => prevRows.map((row) => (row.id === id ? { ...row } : row)));
          return false;
        };
        setRows((prevRows) => prevRows.map((row) => (row.id === id ? { ...row, state } : row)));
        return true; // success
      } catch (error) {
        console.error("Failed to update post state", error);
      }

      return false; // failure
    },
    [posts, rows]
  );

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleTagsChange = useCallback(
    async (id: GridRowId, tags: PostTagType[]): Promise<boolean> => {
      const post = rows.find((row) => row.id === id);
      if (!post) return false;

      try {
        const result = await patchPostTags(post.slug, tags);
        if (!result) {
          setRows((prevRows) =>
            prevRows.map((row) => (row.id === id ? { ...row } : row))
          );
          return false;
        }
        setRows((prevRows) =>
          prevRows.map((row) => (row.id === id ? { ...row, tags } : row))
        );
        return true;
      } catch (error) {
        console.error("Failed to update post tags", error);
      }

      return false;
    },
    [posts, rows, tags]
  );

  const processRowUpdate = useCallback(
    async (newRow: PostRowsDataType, oldRow: PostRowsDataType) => {
  
      // check if the state has changed
      if (newRow.state !== oldRow.state) {
        await handleStateChange(newRow.id, newRow.state).then((result) => {
          if (result === undefined || !result) {
            sendEvent(false, "postsStateChange");
          } else {
            sendEvent(true, "postsStateChange");
          }
        });
      }

      // TODO Check if the post title has changed and update it in the backend

      if (newRow.tags !== oldRow.tags) {
        await handleTagsChange(newRow.id, newRow.tags).then((result) => {
          if (!result) {
            sendEvent(false, "postsTagsChange");
          } else {
            sendEvent(true, "postsTagsChange");
          }
        });
      }

      return newRow;
    },
    [handleStateChange]
  );

  const columns: GridColDef[] = useMemo<GridColDef<PostRowsDataType>[]>(
    () => [
      {
        field: "title",
        label: "Title",
        type: "string",
        editable: false, // TODO
        minWidth: 200,
        maxWidth: 500,
        hideable: false,
      },
      {
        field: "state",
        label: "State",
        type: "singleSelect",
        valueOptions: ["published", "draft", "hidden"],
        minWidth: 140,
        renderCell: (params: GridRenderEditCellParams<PostRowsDataType>) =>
          CustomStateRenderCell(params),
        editable: editable,
      },
      {
        field: "tags",
        label: "Tags",
        type: "singleSelect",
        valueOptions: tags,
        valueFormatter: (value: PostTagType[]) =>
          value.map((tag) => tag.name).join(", "),
        renderCell: (params: GridRenderEditCellParams<PostRowsDataType>) =>
          CustomTagsRenderCell(params),
        renderEditCell: (params: GridRenderEditCellParams<PostRowsDataType>) =>
          CustomTagsEditorCell(params, tags),
        flex: 1,
        editable: editable,
        minWidth: 200,
        maxWidth: 300,
      },
      {
        field: "Created at",
        label: "Created at",
        type: "date",
        renderCell: (params: GridRenderEditCellParams<PostRowsDataType>) =>
          params.value ? format(params.value, "dd-MM-yyyy") : null,
      },
      {
        field: "Updated at",
        label: "Updated at",
        type: "date",
        renderCell: (params: GridRenderEditCellParams<PostRowsDataType>) =>
          params.value ? format(params.value, "dd-MM-yyyy") : null,
      },
      {
        field: "isEvent",
        type: "boolean",
      },
      {
        field: "isFacebookPost",
        type: "boolean",
      },
      {
        field: "Actions",
        type: "actions",
        hideable: false,
        getActions: (params: GridRowParams) => {
          if (!props.actions && editable) {
            return DefaultDataGridActions({
              params: params,
              rowModesModel: rowModesModel,
              setRowModesModel: setRowModesModel,
              handleEditPost: handleEditPost,
              handleDeletePost:handleDeletePost,
            });
          }

          return props.actions?.map((action) =>
            action.action(params, action.actionProps)
          );
        },
      },
    ],
    [handleDeletePost, handleStateChange, rowModesModel]
  );

  return (
    <div>
      <DataGrid
        columns={columns}
        rows={rows}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) =>
          console.error("Row update error", error)
        }
        initialState={props.initialState}
      />
    </div>
  );
};

export default PostsGrid;
