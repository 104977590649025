export const AdminEventsList: string[] = [
    "isLoading", 
    "editorImageUploadResult",
    "editorUpdatePost",
    "editorCreatePost",
    "postsStateChange",
    "postsPinChange",
    "postsDelete",
];

export const setLoading = (value: boolean) => {
    const resultEvent = new CustomEvent("isLoading", {
        detail: { value },
    });
    window.dispatchEvent(resultEvent);
}