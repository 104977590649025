import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { pl } from "date-fns/locale";

import { PinFillIcon } from "../../img";
import { PostType } from "../../types/Post";
import { PostTags } from "../../constants/index";

const EventLabels: {[key: string] : string} = {
  "concert": "Koncert",
  "meeting": "Spotkanie",
  "other": "Inne",
}

interface PostProps {
  post?: PostType;
  className?: string;
  pinned?: boolean;
}

const Post: React.FC<PostProps> = (props: PostProps) => {

  if (props.post) {
    return (
      <article
        className={`relative flex flex-col min-w-[350px] max-w-[450px] max-h-[550px] text-foreground ${props.className}`}
        aria-label="Post"
      >
        <Link
          to={`/post/${props.post.slug}`}
          id="PostThumbnail"
          className="w-full min-h-[200px] max-h-[250px] h-full object-center object-contain rounded-t-2xl bg-purple-300 cursor-pointer"
          aria-label="Miniatura posta"
        >
            {props.post.thumbnail ? (
            <img
              src={props.post.thumbnail}
              alt="thumbnail"
              className="!inline w-full h-full rounded-t-2xl object-cover text-[0px]"
              style={{ maxHeight: '100%', maxWidth: '100%' }}
            />
            ) : (
            <></> // TODO: Add a placeholder image pool here later
            )}
        </Link>
        {props.pinned && (
          <PinFillIcon
            className="absolute top-2 right-2 w-8 h-8"
            fill="rgb(162, 164, 167)"
            stroke="rgb(29, 32, 35)"
            strokeWidth={0.5}
          />
        )}
        <div
          id="PostContent"
          className="flex flex-col gap-y-4 p-6 bg-purple-50 border-2 border-purple-500 rounded-b-2xl w-full h-max"
          aria-label="post-content"
        >
          <div
            id="PostTags"
            className="flex flex-row gap-x-2 max-w-full overflow-x-hidden shrink-0"
            aria-label="Kategorie"
          >
            {props.post.tags.map((tag, index) => (
              <PostTag title={tag.name} key={index} />
            ))}
          </div>
          <div className="flex flex-col gap-y-3 h-full" aria-label="Tytuł">
            <h3 id="PostTitle" className="semiboldheader3" aria-label="Tytuł">
              <Link
                to={`/post/${props.post.slug}`}
                aria-label="Link do posta"
                role="link"
                tabIndex={0}
              >
                {props.post.title}
              </Link>
            </h3>
            <p
              id="PostDescription"
              className="text-clip max-h-[150px]"
              aria-label="Opis posta"
            >
              {props.post.description}
            </p>
          </div>
          <div
            id="PostDate"
            className="flex flex-row w-full justify-end"
            aria-label="Właściwości posta"
          >
            <div className="flex flex-col items-end gap-y-1" aria-label="Data posta">
              {props.post.event? (
              <p className="opacity-80 w-max" aria-label="Data wydarzenia">
                {format(new Date(props.post.event.start_date), "dd.MM.yyyy", { locale: pl })} 
                {props.post.event.end_date ? ` - ${format(new Date(props.post.event.end_date), "dd.MM.yyyy", { locale: pl })}` : ""}
              </p>
              ) : (
              <p className="opacity-80 w-max" aria-label="Data posta"> 
                {format(new Date(props.post.created_at), "dd.MM.yyyy", { locale: pl })}
              </p>
              )}
              <p className="opacity-80 w-max" aria-label={props.post.event? "Rodzaj wydarzenia" : undefined}>
                {props.post.event && EventLabels[props.post.event.category]}
              </p>
            </div>
          </div>
        </div>
      </article>
    );
  } else {
    return (
      <div
        className={`flex flex-col min-w-[350px] max-h-[550px] text-foreground bg-background rounded-2xl ${props.className}`}
        aria-label="Post Placeholder"
      >
        <div
          id="PostThumbnail"
          className="w-full min-h-[240px] rounded-t-2xl bg-purple-300 opacity-80"
        >
          <div className="animate-pulse bg-purple-500 w-full h-[240px] rounded-t-2xl" />
        </div>
        <div
          id="PostContent"
          className="flex flex-col gap-y-4 p-6 bg-purple-50 border-2 border-purple-500 rounded-b-2xl w-full h-full opacity-70"
        >
          <div id="PostTags" className="flex flex-row gap-x-2">
            {Array.from({ length: Math.floor(Math.random() * 3) + 1 }).map(
              (_, index) => (
                <PostTag key={index} />
              )
            )}
          </div>
          <div className="flex flex-col gap-y-3">
            <div className="animate-pulse h-8 w-[180px] bg-purple-500 rounded-2xl" />
            <div
              id="PostDescription"
              className="flex flex-col gap-y-1 max-h-[150px]"
            >
              <div className="animate-pulse h-4 w-[250px] bg-purple-500 rounded-xl" />
              <div className="animate-pulse h-4 w-[200px] bg-purple-500 rounded-xl" />
            </div>
          </div>
          <div id="PostDate" className="flex flex-row w-full justify-end">
            <div className="animate-pulse h-2 w-[80px] bg-purple-500 rounded-md" />
          </div>
        </div>
      </div>
    );
  }
};

interface PostTagProps {
  title?: string;
}

const PostTag: React.FC<PostTagProps> = (props: PostTagProps) => {
  const postTag = PostTags[props.title ?? "default"] || PostTags["default"];

  if (props.title) {
    return (
      <div
        className={`p-2 rounded-xl bg-opacity-80 h-max w-max`}
        style={{ backgroundColor: postTag.bgColor }}
      >
        {postTag.path ? (
          <Link
            to={postTag.path}
            className="basetext"
            style={{ color: postTag.textColor }}
          >
            {postTag.name ?? props.title}
          </Link>
        ) : (
          <p className="basetext" style={{ color: postTag.textColor }}>
            {postTag.name ?? props.title}
          </p>
        )}
      </div>
    );
  } else {
    const postTagKeys = Object.keys(PostTags);
    const randomKey =
      postTagKeys[Math.floor(Math.random() * postTagKeys.length)];
    const randomBgColor = PostTags[randomKey].bgColor;
    const randomTextColor = PostTags[randomKey].textColor;

    return (
      <div
        className={`p-2 rounded-xl bg-opacity-80`}
        style={{ backgroundColor: randomBgColor }}
      >
        <div
          className="w-16 h-2 rounded-md bg-opacity-50 animate-pulse"
          style={{ backgroundColor: randomTextColor }}
        />
      </div>
    );
  }
};

export default Post;
