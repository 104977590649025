import React from "react";

import { useLocation } from "react-router-dom";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { createTheme } from "@mui/material/styles";
import { PageContainer } from "@toolpad/core/PageContainer";

import { AdminNavigation, AdminSubSegments } from "../../constants";
import { Logo } from "../../img";

const Theme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

const getNavigationTitle = (path: string): string => {
  path = path.startsWith("/") ? path.slice(1) : path;
  const navItem = AdminNavigation.find(
    (item) => "segment" in item && item.segment === path
  );
  return navItem && "title" in navItem && navItem.title
    ? navItem.title
    : getSegmentType(path);
};

const getSegmentType = (path: string): string => {
  const navItem = AdminSubSegments.find((item) => item.segment === path);
  return navItem ? navItem.title : (path.split("/").pop() ?? "");
};

type DashboardLayoutProps = {
  children: React.ReactNode;
};

const AdminDashboard: React.FC<DashboardLayoutProps> = (
  props: DashboardLayoutProps
) => {
  const location = useLocation();
  const breadcrumbs = location.pathname
    .split("/")
    .filter(Boolean)
    .map((segment, index, array) => {
      const path = "/" + array.slice(0, index + 1).join("/");
      return {
        title: getNavigationTitle(path),
        href: path,
        path: path,
      };
    });

  return (
    <AppProvider 
    theme={Theme} 
    navigation={AdminNavigation}
    branding={{
        logo: <Logo className="w-10 h-10"/>,
        title: "Parafia Bielsko",
    }}
    >
      <DashboardLayout>
        <PageContainer
          title={breadcrumbs[breadcrumbs.length - 1]?.title || ""}
          breadcrumbs={breadcrumbs}
        >
          {props.children}
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
  );
};

export default AdminDashboard;
