import React, { useState } from "react";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  Download,
  Fullscreen,
  Thumbnails,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import ImageGallery from "../../components/ui/ImageGallery";
import StaticPageLayout from "../../components/StaticPages/Layout";
import {
  BannerSenior,
  SoarThumbnail,
  Soar01,
  Soar02,
  Soar03,
  Soar04,
  Soar05,
  Soar06,
  Soar07,
  Soar08,
  Soar09,
} from "../../img";
import {
  soar_address,
  soar_zipcode,
  soar_phone,
  soar_bank_info,
  soar_director,
  soar_director_deputy,
} from "../../constants/Information";

const Soar: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  return (
    <>
      <StaticPageLayout title={"Soar"} background={BannerSenior}>
        <div className="flex flex-col lg:flex-row gap-x-4 gap-y-2 h-fit items-center">
          <img
            src={SoarThumbnail}
            alt="Soar thumbnail"
            className="max-w-96 max-h-96"
          />
          <div className="flex flex-col h-max gap-y-3">
            <h2 className="semiboldheader4">
              Ewangelicki Dom Opieki &quot;SOAR&quot;
            </h2>
            <div className="flex flex-col gap-y-0">
              <a
                href="https://maps.app.goo.gl/s6pJ38yVx64HhZ266"
                className="clickable"
              >
                {soar_address}
              </a>
              <p>{soar_zipcode}</p>
              <p>
                tel.{" "}
                <a href={`tel:${soar_phone}`} className="clickable">
                  {soar_phone}
                </a>
              </p>
            </div>
            <div className="flex flex-col gap-y-0">
              <p>
                <span className="boldbasetext">NIP:</span> 
                {" "}
                {soar_bank_info.nip}
              </p>
              <p>
                <span className="boldbasetext">REGION:</span>
                {" "}
                {soar_bank_info.region}
              </p>
              {Object.keys(soar_bank_info.accounts).map((key) => (
                <p key={key}>
                  <span className="boldbasetext">{key}:</span>
                  {" "}
                  {soar_bank_info.accounts[key]}
                </p>
              ))}
            </div>
            <div className="flex flex-col gap-y-0">
              <p>
                <span className="boldbasetext">Dyrektor:</span>
                {""}
                {soar_director}
              </p>
              <p>
                <span className="boldbasetext">
                  Zastępca dyrektora/ kierownik opiekunów
                </span>
                {" "}
                {soar_director_deputy}
              </p>
            </div>
          </div>
        </div>
        <p>
          W naszym Domu, w myśl naszych założeń, każdy z 25 mieszkańców
          indywidualnie decyduje o swoim stylu życia, sposobie spędzania czasu,
          kontaktach z innymi, diecie i ewentualnym leczeniu, aby każdy czuł się
          tu jak we własnym domu.
          <br />
          <br />
          Zapewniamy:
          <br />
          - jednoosobowe pokoje, nowoczesne łazienki
          <br />
          - umeblowanie i wystrój każdego pokoju według indywidualnych upodobań
          <br />
          - całodobową wykwalifikowaną opiekę pielęgniarską, pokoje zaopatrzone
          są w system przywołania pomocy
          <br />
          - opiekę psychologiczną
          <br />
          - fachowy personel kuchenny i pomocniczy
          <br />
          - opiekę duszpasterską - codziennie rano o godz. 9.00 zapraszamy do
          wspólnej modlitwy, czytania i rozważania Słowa Bożego; raz w tygodniu
          (środa) odbywa się godzina biblijna o godz. 15.00, mniej sprawni mają
          możliwość przystąpienia do spowiedzi i Komunii Św.
          <br />
          <br />
          ponadto oferujemy:
          <br />
          wspólną świetlicę wyposażoną w telewizor i podręczną bibliotekę
          <br />
          gimnastykę rekreacyjną i rehabilitacyjną
          <br />
          zajęcia aktywizujące, wycieczki, koncerty, itp.
          <br />
          <br />
          Koszt pobytu w Ewangelickim Domu Opieki „SOAR” od lutego 2022 roku
          wynosi 4660zł miesięcznie
          <br />
          /zgodnie z Zarządzeniem nr 2/2022 Starosty Bielskiego z dnia 19
          stycznia 2022 r. - Dziennik Urzędowy Województwa Śląskiego Poz. 476 z
          dnia 24 stycznia 2022 r /<br />
        </p>
        <ImageGallery
          images={[Soar01, Soar02, Soar03, Soar04]}
          setIndex={setCurrentIndex}
          startIndex={0}
        />
        <p>
          Piękny, słoneczny, jeszcze letni dzień - no i grill w ogrodzie!! To
          wyglądało malowniczo: na tle zieleni drzew białe stoły i fotele, a
          obok altany czarny, dymiący grill...
          <br />
          To był czas odpoczynku, upajania się ciepłym powietrzem, świeżością
          zieleni i żywymi barwami kwiatów naszego ogrodu. Są nadzieje na
          usprawnienie &quot;komunikacji pionowej&quot;! Wnikliwi obserwatorzy
          soarowego życia spostrzegli obcych ludzi dokonujących pomiarów i
          wysnuli na tej podstawie wniosek, że zbliża się chwila budowy dźwigu
          osobowego na trzecie piętro.
          <br />
        </p>
        <div className="flex flex-row w-full justify-center">
          <img
            src={Soar05}
            alt="Kolejka na szczyt Soar'u"
            className="max-w-96 max-h-96 rounded-2xl cursor-pointer"
            onClick={() => setCurrentIndex(4)}
          />
        </div>
        <p>
          Urządzenie transportowe, nasza kronika nazwała je &quot;kolejką zębatą
          na szczyt Soar&quot;...
          <br />
        </p>
        <div className="flex flex-row w-full justify-center">
          <img
            src={Soar06}
            alt="Święta w Soarze"
            className="max-w-96 max-h-96 rounded-2xl cursor-pointer"
            onClick={() => setCurrentIndex(5)}
          />
        </div>
        <p>
          Nastrój nadchodzących świąt daje się odczuć w całym domu. Nasze i
          personelu zachowania i prace dają nam poczucie braterstwa. Wieczerza
          wigilijna to uroczyste rodzinne spotkanie. Do stołu, zgodnie ze
          staropolskim zwyczajem, zostali zaproszeni goście naszych domowników.
          <br />
        </p>
        <div className="flex flex-row w-full justify-center">
          <img
            src={Soar07}
            alt="Soar - tradycje"
            className="max-w-96 max-h-96 rounded-2xl cursor-pointer"
            onClick={() => setCurrentIndex(6)}
          />
        </div>
        <p>
          Nasz dom jest zakorzeniony w tradycji diakonijnej,powstałej z
          inicjatywy superintendenta ks.dr.Teodora Haase.
          <br />
          <br />
          W obecnym kształcie zbudowany został w latach 1906-7 dzięki ofiarności
          miejscowych ewangelików. Początkowo był to dom dla sierot; również po
          drugiej wojnie światowej pełnił funkcję domu dziecka. W latach 50,
          ówczesne władze polityczne zlikwidowały wyznaniowe placówki tego typu.
          Przemiany społeczno-polityczne umożliwiły rozpoczęcie nowej formy
          działalności, potrzeby też już były inne. Przy wsparciu ewangelickiej
          organizacji &quot;Znaki Nadziei&quot; (Niemcy) powołano do życia dom
          opieki dla osób starszych.Prace adaptacyjne rozpoczęto w 1992 roku,a
          budynek poświęcono 18 marca 1995 r. Jego mieszkańcami są osoby różnych
          wyznań, jednak przede wszystkim z Bielska-Białej i diecezji
          cieszyńskiej. Część mieszkańców jest rekomendowana przez tę
          organizację.
          <br />
        </p>
        <div className="flex flex-col lg:flex-row gap-x-4 gap-y-2 h-fit items-center">
          <img
            src={Soar08}
            alt="Soar thumbnail"
            className="max-w-96 max-h-96"
          />
          <p className="h-max">
            &quot;Schroń się tam szybko,bo nie mogę nic uczynić,dopóki tam nie
            wejdziesz! Dlatego to miasto nazywa się Soar. Gdy słońce wzeszło nad
            ziemią,Lot wszedł do Soaru&quot;.1 Księga Mojżeszowa 19,22-23
            <br />
            <br />
            &quot;Ochotnego dawcę Bóg miłuje&quot; - jeśli chcesz, także Ty
            możesz wspomóc nasz Dom finansowo. Będziemy wdzięczni za każdy dar.
            <br />
            Wpłat można dokonywać na konto Ewangelickiego Domu Opieki SOAR:
            <br />
            <span className="boldbasetext">
              {soar_bank_info.accounts["Konto darowizn"]}
            </span>
          </p>
        </div>
        <div className="flex flex-row w-full justify-center">
          <div className="flex flex-col items-center">
            <img
              src={Soar09}
              alt="Soar thumbnail"
              className="max-w-96 max-h-96 rounded-2xl cursor-pointe"
              onClick={() => setCurrentIndex(7)}
            />
            <p className="opacity-80">
              Zdj.: M.Feodorów; tekst: M.Legendź; z wykorzystaniem fragmentów
              &quot;Kroniki&quot;.
            </p>
          </div>
        </div>
      </StaticPageLayout>
      <Lightbox
        plugins={[Download, Fullscreen, Zoom, Thumbnails]}
        index={currentIndex}
        open={currentIndex >= 0}
        close={() => setCurrentIndex(-1)}
        slides={[
          Soar01,
          Soar02,
          Soar03,
          Soar04,
          Soar05,
          Soar06,
          Soar07,
          Soar09,
        ].map((image) => ({ src: image }))}
      />
    </>
  );
};

export default Soar;
