import React, { useState, useEffect } from "react";

import PostPageLayout from "../../components/PostPages/Layout";
import Post from "../../components/ui/Post";
import { getPosts, getPinnedPosts } from "../../services/postService";
import { PostType } from "../../types/Post";
import { BannerClock } from "../../img";

const News: React.FC = () => {
  const [posts, setPosts] = useState<PostType[]>([]);
  const [pinnedPosts, setPinnedPosts] = useState<PostType[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await getPosts("news");
      const pinned_posts = await getPinnedPosts("news");
      
      if (pinned_posts.length > 0) {
        setPosts(posts.filter((post : PostType) => !pinned_posts.some(pinnedPost => pinnedPost.id === post.id)));
      }
      else {
        setPosts(posts);
      }
      setPinnedPosts(pinned_posts);
    };
    fetchPosts();
  }, []);

  return (
    <PostPageLayout title="Ogłoszenia" backgroundImage={BannerClock}>
      {pinnedPosts.length > 0 && pinnedPosts.map((post, index) => (
        <Post
          post={post}
          key={index}
          className="basis-[51%] lg:basis-[34%] 2xl:basis-[26%] grow-1 mx-auto"
          pinned
        />
      ))}
      {posts.length === 0 && pinnedPosts.length === 0
        ? [...Array(12)].map((_, index) => (
            <Post
              key={index}
              className="basis-[51%] lg:basis-[34%] 2xl:basis-[26%] grow-1 mx-auto"
            />
          ))
        : posts.map((post, index) => (
            <Post
              post={post}
              key={index}
              className="basis-[51%] lg:basis-[34%] 2xl:basis-[26%] grow-1 mx-auto"
            />
          ))}
    </PostPageLayout>
  );
};

export default News;
