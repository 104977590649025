import React from "react";

interface StaticPageLayoutProps {
  title: string;
  children: React.ReactNode;
  background?: string;
}

const StaticPageLayout: React.FC<StaticPageLayoutProps> = (
  props: StaticPageLayoutProps
) => {
  return (
    <main className="relative flex-col w-full">
      <div
        id="StaticPageBackground"
        className="absolute w-full h-[500px] bg-cover bg-center -z-10"
        style={{
          backgroundImage: `url(${props.background})`,
          backgroundPosition: "center",
        }}
      >
        <div className="absolute backgroundImageFilter" />
        <div className="md:hidden absolute backgroundImageFilterMobile" />
        <div className="absolute backgroundImageFilter2" />
      </div>
      <article className="relative w-full h-max min-h-[520px]">
        <div className="py-12 mx-8 lg:mx-[11.25%] flex flex-col gap-y-8">
          <div className="flex flex-row p-4 rounded-2xl bg-background bg-opacity-70 md:w-max justify-center md:justify-start">
            <h2 className="boldheader3 lg:boldheader2 2xl:boldheader1 text-purple-500">
              {props.title}
            </h2>
          </div>
          <div className="flex flex-col p-4 rounded-2xl bg-purple-50 basetext text-foreground border-2 border-purple-500 w-full gap-y-4">
            {props.children}
          </div>
        </div>
      </article>
    </main>
  );
};

export default StaticPageLayout;
