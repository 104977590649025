import React from "react";

import StaticPageLayout from "../../components/StaticPages/Layout";
import { BannerLuter, Logo2, SeniorBanner } from "../../img";

const Senior: React.FC = () => {
  return (
    <StaticPageLayout background={BannerLuter} title={"Senior"}>
      <div className="flex flex-col gap-y-6">
        <div className="w-full flex flex-row justify-center">
          <img src={SeniorBanner} alt="Banner sponsora" />
        </div>
        <div className="flex flex-col">
          <p>
            Parafia Ewangelicko-Augsburska Bielsko realizuje projekt pt
            &quot;Standard opieki senioralnej w Bielsku-Białej&quot; finansowany
            ze środków Europejskiego Funduszu Społecznego w ramach poddziałania:
            9.2.5. Rozwój usług społecznych Regionalnego Programu Operacyjnego
            dla Województwa Śląskiego
          </p>
          <ul className="list-none py-2">
            <li>Całkowita wartość projektu: 754 643,75 zł.</li>
            <li>Wkład Funduszy Europejskich: 641 447,18 zł.</li>
            <li>Dysponenci budżetu państwa: 60 371,51 zł.</li>
            <li>Wkład własny: 52 825,06 zł.</li>
          </ul>
          <p>
            Projekt dotyczy wsparcia w samodzielności, niezależności, utrzymaniu
            lub przywróceniu wysokiej jakości życia pomimo ograniczeń związanych
            z procesem starzenia się 50 osób 60+ zamieszkujących Miasto
            Bielsko-Białą oraz wsparcia 20 opiekunów faktycznych w sprawowaniu
            opieki nad swoimi bliskimi w miejscu zamieszkania w formie
            następujących form wskazanych w Regulaminie konkursu jak i
            Skoordynowanej Opiece Senioralnej (SOS):
          </p>
          <ul className="list-decimal list-inside py-2">
            <li>
              Klub Seniora: gimnastyka/rehabilitacja, zajęcia plastyczne, grupa
              terapeutyczno-rozwojowe, muzykoterapia, wycieczki, wieczorki
              taneczne dla 20 uczestników;
            </li>
            <li>
              Realizacja usług opiekuńczych świadczonych w miejscu zamieszkania
              dla 15 uczestników;
            </li>
            <li>
              Wsparcie 20 opiekunów faktycznych w formie doradztwa
              specjalistycznego: prawnik, pracownik socjalny,
              psycholog/psychoterapeuta; konsultacje pielęgniarsko-opiekuńcze,
              life coaching w średnim wymiarze około 10 godzin miesięcznie;
            </li>
            <li>
              Dowożenie posiłków do miejsc zamieszkania dla 10 uczestników.
            </li>
          </ul>
          <p>
            Projekt będzie realizowany w okresie 1 wrzesień 2018 do 31 grudzień
            2020 roku.(przedłużenie do marca 2021 r.)
          </p>
          <p>
            Parafia Ewangelicko -Augsburska Bielsko, pl. Marcina Lutra 12 w
            Bielsku-Białej wyraża gotowość zachowania trwałości liczby miejsc 
            świadczenia usług społecznych w ramach Klubu Seniora oraz usług opiekuńczych.
          </p>
        </div>
        <div className="flex flex-col gap-y-2">
          <h3 className="semiboldheader4">
            Zapytanie ofertowe z dnia 30.11.2019
          </h3>
          <p>
            Parafia Ewangelicko-Augsburska Bielsko zaprasza do składania ofert
            na wykonanie prac remontowych celem przekształcenia lokalu
            mieszkalnego w parafialny klub seniora.
          </p>
          <div className="border-2 border-purple-500 p-2 rounded-sm">
            <p className="italic">
              Treść ogłoszenia została zmieniona
            </p>
            <ul className="list-decimal list-inside py-2">
              <li>
                Zmieniono plik Przedmiar robót (poprzednia wersja pliku była
                uszkodzona) 
              </li>
              <li>
                Na wniosek podmiotu zainteresowanego dodano Przedmiar
                z oznaczeniami KNR i KSNR (jako osobny plik)
              </li>
            </ul>
          </div>
          <div>
            <p>Załączniki:</p>
            <ul className="list-none">
              <li>
                <a
                  href="./media/zapytanie-roboty-budowlane-senior.pdf"
                  className="clickable"
                >
                  Zapytanie roboty budowlane senior
                </a>
              </li>

              <li>
                <p>Załącznik nr 1 - {" "}
                  <a
                    href="./media/Zalacznik-nr-1-Projektu-budowlany.zip"
                    className="clickable"
                  >
                    Projekt budowlany
                  </a>
                </p>
              </li>

              <li>
                <p>
                  Załącznik nr 1 - {" "}
                  <a
                    href="./media/Zalacznik-nr-1-Przedmiar-robot.pdf"
                    className="clickable"
                  >
                    Przedmiar robót
                  </a>
                </p>
              </li>
              <li>
                Załącznik nr 2 - {" "}
                <a
                  href="./media/Zalacznik-nr-2-formularz-oferty.doc"
                  className="clickable"
                >
                  Formularz oferty
                </a>
              </li>

              <li>
                <p>
                  Załącznik nr 3 - {" "}
                  <a
                    href="./media/Zalacznik-nr-3-wzor-umowy.pdf"
                    className="clickable"
                  >
                    Wzór umowy
                  </a>
                </p>
              </li>

              <li>
                <p>
                  Załącznik nr 4 - {" "}
                  <a
                    href="./media/Zalacznik-nr-4-harmonogram-prac.pdf"
                    className="clickable"
                  >
                    Harmonogram prac
                  </a>
                </p>
              </li>

              <li>
                <a href="./media/Przedmiar_Etap_I.pdf" className="clickable">
                  Przedmiar z oznaczeniami KNR i KSNR
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col gap-y-2">
          <h3 className="semiboldheader4">Ogłoszenie</h3>
          <p>
            Parafia realizuje projekt pt &quot;Standard opieki senioralnej w
            Bielsku-Białej&quot; finansowany ze środków Europejskiego Funduszu
            Społecznego.
          </p>
          <p>
            W związku z tym zapraszamy opiekunów faktycznych do zgłaszania swoich starszych
            rodziców, osoby samotne wymagające opieki by skorzystały z usług
            opiekuńczych realizowanych przez wykwalifikowany personel opiekunek.
          </p>
          <p>
            Średnia ilość godzin miesięcznie : 15 na jedna osobę
          </p>
          <p>
            Opiekun świadczy usługi BEZPŁATNIE w miejscu zamieszkania, pomaga w
            czynnościach życia codziennego: mycie, sprzątanie, karmienie,
            gotowanie posiłków ew. robienie zakupów.
          </p>
          <p>
            W ramach projektu powstanie Klub Seniora dla 20 uczestników -
            planowany czas rozpoczęcia maj 2019, dowożone są posiłki, prowadzone
            jest doradztwo psychologiczno-prawne.
          </p>
          <p>
            Termin zgłaszanie chętnych do 28 lutego. Ilość miejsc ograniczona.
          </p>
          <p>
            Bezpośrednio po zakończeniu nabożeństwa można zgłaszać osoby
            zainteresowane oraz telefonicznie, bezpośrednio lub mailowo do EDO
            Soar do pani Beaty Pawlak- Młodzik, tel 33 812 6164, mail:
            domsoar@o2.pl. Proszę podać imię i nazwisko, adres i numer
            kontaktowy. Z osobami zainteresowanymi będziemy umawiać się na
            spotkanie.
          </p>
        </div>

        <div className="flex flex-col gap-y-2">
          <h3 className="semiboldheader4">
            Sprawozdanie z realizacji projektu &quot;Standard opieki senioralnej
            w Bielsku Białej&quot;
          </h3>
          <ul className="list-none list-inside">
            <li>
              Wartość projektu: dofinansowanie - 701 818,69zł; wkład własny - 52 825,06zł.
            </li>
            <li>
              Łączna kwota wydatków do 28.02.2019 - 114 382,80zł w tym 25 %
              kosztów pośrednich
            
              (zarządzanie, księgowość, obsługa administracyjna i prowadzenie
              biura)
            </li>
          </ul>

          <div>
            <h4 className="boldbasetext">Zadanie 1</h4>
            <p>
              Klub Seniora: etap 1 remont, etap 2 - działalność Klubu Koszt - 75
              000zł (tylko koszty poniesione w projekcie)
            </p>
            <p>
              W ramach projektu założono remont mieszkania - pomieszczenia na
              terenie budynku parafialnego przy Placu Marcina Lutra 2.
            </p>
            <p>
              Prace zostały rozpoczęte z opóźnieniem - w grudniu (zapytanie
              ofertowe z dnia 30.11.2018) ze względu na konieczność dopełnienia
              formalności związanych z pozwoleniami, ponowną oceną zakresu prac.
              Budynek jest objęty ochroną konserwatora zabytków i wszelkie prace
              w nim prowadzone odbywają się pod nadzorem. Budynek jest wiekowy
              więc podczas prac remontowych pojawiły się nieprzewidziane
              przeszkody, które utrudniały kontynuację prac. Zakres wykonanych
              robót na dzień 28.02.2019r. to: wymiana instalacji elektrycznej,
              przygotowanie ścian pod gładź i rozpoczęcie procesu kładzenia
              gładzi, odsłonięcie sufitów, belek stropowych i ich
              zabezpieczenie, rozbiórka na podstawie ekspertyzy nadzoru
              budowlanego.
            </p>
            <p>
              Przewidywany termin uruchomienia Klubu to 1 czerwiec 2019r.
              zakończenie projektu to grudzień 2020r.
            </p>
            <p>
              W ramach klubu będą odbywać się następujące zajęcia:
            </p>
            <ul className="list-disc list-inside py-1">
              <li>
                gimnastyka kondycyjno-rehabilitacyjna - 1 x tyg. 2h.
              </li>
              <li>
                zajęcia plastyczne 1x tyg. 2h.
              </li>
              <li>
                grupa terapeutyczno-rozwojowa - 1x tyg. 3h.
              </li>
              <li>
                muzykoterapia - 1 x tyg. 2h - elementy relaksacji, realizacja
                pasji muzycznych.
              </li>
            </ul>
            <p>
              W czasie kiedy nie są prowadzone regularnie zajęcia, każdy z
              Uczestników będzie miał do dyspozycji: kącik z książkami,
              możliwość posłuchania muzyki, kącik rozmów, gry planszowe. Nad
              wszystkim będzie czuwał opiekun grupy (1 etat). Podczas pobytu w
              Klubie każdy z Uczestników otrzyma drobny poczęstunek i jeden
              ciepły posiłek w sytuacji gdy będzie przebywać dłużej niż 5
              godzin. Przewidujemy organizację 3 jednodniowych wycieczek
              turystyczno-krajoznawczych.
              <br />
              Uczestnicy będą mogli wziąć udział w 5 wieczorkach tanecznych.
            </p>
          </div>

          <div>
            <h4 className="boldbasetext">Zadanie 2</h4>
            <p>
              Usługi opiekuńcze - koszt 8 706,24zł.
            </p>
            <p>
              W ramach zadania, w bieżącym okresie rozliczeniowym przeprowadzono
              80h szkolenia dla opiekunów osób starszych, zgodnie ze Standardem
              Opieki Senioralnej. Uczestnicy otrzymali rzetelną wiedzę
              teoretyczną a przede wszystkim praktyczną w temacie sprawowania
              opieki nad osobą starszą, leżącą. Odbył się również trening
              umiejętności interpersonalnych. Podczas warsztatów opiekunowie
              otrzymali poczęstunek i materiały szkoleniowe.
            </p>
            <p>
              Mamy już zamkniętą listę uczestników objętych usługami - 15 osób.
              Usługi ruszą najpóźniej 1 maja 2019r. Każda osoba otrzyma średnio
              15-17h usług na miesiąc. Przewidujemy pobieranie drobnych opłat
              ok. 2 zł/h (konieczność wynikająca z zapisów regulaminu konkursu,
              z ramienia którego realizowany jest projekt)
            </p>
          </div>

          <div>
            <h4 className="boldbasetext">Zadanie 3</h4>
            <p>
              Doradztwo dla opiekunów faktycznych - koszt 0,00zł
            </p>
            <p>
              Wraz z uczestnikami usług opiekuńczych będziemy realizować usługi
              doradcze.
            </p>
            <p>
              Doradztwo będzie odbywać się na rzecz opiekunów faktycznych osób
              starszych, którymi są dzieci, znajomi, dalsi krewni. Planowani
              specjaliści to: psycholog, coach, prawnik.
            </p>
          </div>

          <div>
            <h4 className="boldbasetext">Zadanie 4</h4>
            <p>
              Dowożenie posiłków - koszt 7 800zł
            </p>
            <p>
              Posiłki są dowożone od 6 grudnia 2018r. do 10 osób zamieszkujących
              miasto Bielsko-Białą. Są to osoby leżące lub mające trudności z
              przemieszczaniem się i w trudniejszej sytuacji finansowej. Posiłek
              ciepły, przygotowywany w danym dniu zgodnie z przepisami
              sanitarnymi. Posiłki w bieżącym okresie rozliczeniowym były
              przygotowywane i dowożone przez Dom SOAR i rozliczane wewnętrznym
              dokumentem - nota księgową. W związku z tym był to posiłek
              dwudaniowy bo takie rozwiązanie pozwoliło wyjść naprzeciw
              oczekiwaniom klientów.
            </p>
            <p>
              Od 22 marca posiłki są zlecane firmie cateringowej i realizowane
              bez naruszenia jakości.
            </p>
          </div>
        </div>
        <div className="flex flex-row w-full justify-center">
          <img
            src={Logo2}
            alt="Logo Parafii Ewangelicko-Augsburskiej w Bielsku-Białej"
            className="max-w-[180px] md:max-w-[360px]"
          />
        </div>
      </div>
    </StaticPageLayout>
  );
};

export default Senior;
