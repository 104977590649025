import React from "react";

import { SpacerBlueUp, SpacerBlueDown, SpacerWhiteDown2 } from "../../img";

type SpacerVariants = "BlueUp" | "BlueDown" | "WhiteDown";
type SpacerImagesType = { [key in SpacerVariants]: string };
const SpacerImages: SpacerImagesType = {
  BlueUp: SpacerBlueUp,
  BlueDown: SpacerBlueDown,
  WhiteDown: SpacerWhiteDown2,
};

interface SpacerProps {
  variant: SpacerVariants;
}

const Spacer: React.FC<SpacerProps> = (props: SpacerProps) => {
  return (
    <div
      className={`relative w-[105%] h-16 ${
        props.variant.endsWith("Up") ? "mt-[-2px]" : "mb-[-2px]"
      }`}
    >
      <div
        style={{ backgroundImage: `url(${SpacerImages[props.variant]})` }}
        className="absolute bottom-0 top-0 w-full h-full object-cover"
      ></div>
    </div>
  );
};

export default Spacer;
