import { PageColors } from "./Pages";
import { AdminNavigation, AdminSubSegments } from "./AdminNavigation";

export const MEDIA_URL = "";

export {
    PageColors,
    AdminNavigation,
    AdminSubSegments
}

export const NavigationLinks = [
    {
        name: "Informacje",
        path: "/information",
    },
    {
        name: "Ogłoszenia",
        path: "/news",
    },
    {
        name: "Kalendarium",
        path: "/calendar"
    },
    {
        name: "Parafia",
        links: [
            {
                name: "Historia",
                path: "/history"
            },
            {
                name: "Chór",
                path: "/choir"
            },
            {
                name: "Publikacje",
                path: "/publications"
            },
            {
                name: "Nabożeństwa",
                path: "/devotions",
            },
            {
                name: "Złóż ofiarę",
                path: "/offerings",
            },
            {
                name: "Cmentarze",
                path: "https://bielsko-luteranie.grobonet.com/grobonet/",
            },
            {
                name: "Stary cmentarz ewangelicki",
                path: "https://www.facebook.com/people/Stary-Cmentarz-Ewangelicki-w-Bielsku/100064505359921/",
            }
        ]
    },
    {
        name: "Senior",
        links: [
            {
                name: "Soar",
                path: "/soar"
            },
            {
                name: "Informacje",
                path: "/senior"
            }
        ]
    },
];

function FindPath(name: string): string | undefined {
    const link = NavigationLinks.find(
      (link) =>
        link.name === name || link.links?.some((link2) => link2.name === name)
    );
  
    if (link && link.links) {
      return link.links.find((link2) => link2.name === name)?.path;
    }
  
    return link?.path;
  }
  
export const PostTags: {
    [key: string]: { name?: string, bgColor: string; textColor?: string; path?: string };
  } = {
    default: {
      bgColor: PageColors["Ogłoszenia"]["titleBackground"],
      textColor: PageColors["Ogłoszenia"]["titleColor"],
    },
    news: {
      name: "Aktualności",
      bgColor: PageColors["Ogłoszenia"]["titleBackground"],
      textColor: PageColors["Ogłoszenia"]["titleColor"],
      path: FindPath("Ogłoszenia"),
    },
    publications: {
      name: "Publikacje",
      bgColor: PageColors["Publikacje"]["titleBackground"],
      textColor: PageColors["Publikacje"]["titleColor"],
      path: FindPath("Publikacje"),
    },
    events: {
        name: "Wydarzenia",
        bgColor: PageColors["Kalendarium"]["titleBackground"],
        textColor: PageColors["Kalendarium"]["titleColor"],
        path: FindPath("Kalendarium"),
    },
    history: {
        name: "Historia",
        bgColor: PageColors["default"]["titleBackground"],
        textColor: PageColors["default"]["titleColor"],
        path: FindPath("Historia"),
    }
  };