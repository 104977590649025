import React, { useState } from "react";

import {
  GridRowParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridActionsCellItem,
  GridActionsCellItemProps,
} from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PushPinIcon from "@mui/icons-material/PushPin";

function PinPostGridAction(
  params: GridRowParams,
  handlePinPost: (id: number) => void,
) {
  return (
    <GridActionsCellItem
      key="pin"
      icon={<PushPinIcon fill="var(--mui-palette-text-primary)" />}
      onClick={() => handlePinPost(params.row.id)}
      label="Pin"
    />
  );
};

function DeletePostActionItem({
  deletePost,
  ...props
}: GridActionsCellItemProps & { deletePost: () => void }) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete post?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone. Are you sure you want to delete this
            post?
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
                // Add a function to send a request to the backend to delete the post
                deletePost();
              }}
              color="warning"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

type DefaultDataGridActionsProps = {
  params: GridRowParams;
  rowModesModel: GridRowModesModel;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  handleEditPost: (slug: string) => void;
  handleDeletePost: (id: GridRowId) => void;
};

function DefaultDataGridActions({
  params,
  rowModesModel,
  setRowModesModel,
  handleEditPost,
  handleDeletePost,
}: DefaultDataGridActionsProps) {
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

  if (isInEditMode) {
    return [
      <GridActionsCellItem
        key="save"
        icon={<SaveIcon />}
        label="Save"
        sx={{ color: "primary.main" }}
        onClick={handleSaveClick(params.id)}
      />,
      <GridActionsCellItem
        key="cancel"
        icon={<CancelIcon />}
        label="cancel"
        sx={{ color: "primary.main" }}
        onClick={handleCancelClick(params.id)}
      />,
    ];
  }
  return [
    <GridActionsCellItem
      key="edit"
      icon={<EditIcon fill="var(--mui-palette-text-primary)" />}
      onClick={() => handleEditPost(params.row.slug)}
      label="Edit"
    />,
    <DeletePostActionItem
      key="delete"
      icon={<DeleteIcon fill="var(--mui-palette-text-primary)" />}
      deletePost={() => handleDeletePost(params.id)} // TODO
      label="Delete"
    />,
  ];
};

export { 
    DefaultDataGridActions, 
    PinPostGridAction 
};
