import React from "react";

import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";

import { PostTagType } from "../../../types/Post";

type CustomTagsEditorProps = {
  params: GridRenderEditCellParams;
  tags: PostTagType[];
};

function CustomTagsEditor (props: CustomTagsEditorProps) {
  const { id, value, field } = props.params;
  const tags = props.tags;
  const apiRef = useGridApiContext();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const newValue = (event.target.value as string[]).map((tagName: string) => tags.find((tag) => tag.name === tagName));
    apiRef.current.setEditCellValue({ id: id, field: field, value: newValue });
  };

  return (
    <Select
      labelId="post-tags-selector"
      id="post-tags-selector"
      multiple
      value={value.map((tag: PostTagType) => tag.name)}
      onChange={handleChange}
      sx={{ width: "100%" }}
    >
      {tags.map((tag, index) => (
        <MenuItem key={index} value={tag.name}>
          {tag.name}
        </MenuItem>
      ))}
    </Select>
  );
}

const CustomTagsEditorCell = (params: GridRenderEditCellParams, tags: PostTagType[]) => <CustomTagsEditor params={params} tags={tags}/>

function CustomTagsRenderCell (props: GridRenderEditCellParams) {
  if (props.value === null) return null;

  return (
    <>
    {props.value.map(
      (tag: PostTagType) => <Chip key={tag.id} label={tag.name} color="primary" style={{ margin: "2px"}}/>)
    }
    </>
  );
}

export { CustomTagsEditorCell, CustomTagsRenderCell };