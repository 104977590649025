import React from "react";
import { type Navigation } from "@toolpad/core/AppProvider";
import {
  DashboardIcon,
  TableIcon,
  ToolsIcon,
  PageIcon,
} from "../img";

export const AdminNavigation: Navigation = [
  // Pages
  {
    kind: "header",
    title: "Pages",
  },
  {
    segment: "admin/pages/dashboard",
    title: "Dashboard",
    icon: <DashboardIcon fill="var(--mui-palette-text-primary)" className="w-6 h-6"/>,
  },

  // Tables
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Tables",
  },
  {
    segment: "admin/tables/posts",
    title: "Posts",
    icon: <TableIcon fill="var(--mui-palette-text-primary)" className="w-6 h-6"/>,
    pattern: "admin/actions/edit_post{/:slug}*",
  },

  // Actions
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Actions",
  },
  {
    segment: "admin/actions/new_post",
    title: "New Post",
    icon: <PageIcon fill="var(--mui-palette-text-primary)" className="w-6 h-6"/>,
  },
  {
    segment: "admin/actions/pin_posts",
    title: "Pin Posts",
    icon: <ToolsIcon fill="var(--mui-palette-text-primary)" className="w-6 h-6"/>,
  },
];

// Sub-segments between the main segments
export const AdminSubSegments: { segment: string; title: string }[] = [
  {
    segment: "admin",
    title: "Dashboard",
  },
  {
    segment: "admin/tables",
    title: "Tables",
  },
  {
    segment: "admin/pages",
    title: "Pages",
  },

  // Actions
  {
    segment: "admin/actions",
    title: "Actions",
  },
  {
    segment: "admin/actions/new_post",
    title: "New Post",
  },
  {
    segment: "admin/actions/edit_post",
    title: "Edit Post",
  },
  {
    segment: "admin/actions/pin_posts",
    title: "Pin Posts",
  },
];
