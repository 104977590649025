import { api } from './api';
import { PostType, PostContentType } from '../types/Post';
import { isValid, format } from 'date-fns';

export const getPinnedPosts = async (page: string): Promise<PostType[]> => {
    const url = `/posts/pinned?page=${page}`;
    const response = await api.get(url);

    if (response.status !== 200) {
        console.error("Error fetching pinned posts {%s}", response.data["detail"]);
        return [] as PostType[];
    }
    
    return response.data as PostType[];
}
export const getPosts = async (page?: string, slug?: string, eventType?: string, eventDate?: Date): Promise<PostType[]> => {
    let url = `/posts`;
    const params = new URLSearchParams();
    if (page) params.append('page', page);
    if (slug) params.append('slug', slug);
    if (eventType) params.append('eventCategory', eventType);
    if (eventDate && isValid(eventDate)) params.append('eventDate', format(eventDate, 'yyyy-MM-dd'));

    url = `${url}?${params.toString()}`;

    const response = await api.get(url);

    if (response.status !== 200) {
        console.error("Error fetching posts {%s}", response.data["detail"]);
        return [] as PostType[];
    }

    return response.data as PostType[];
}
export const getPostContent = async (slug: string): Promise<PostContentType[]> => {
    const url = `/posts/content?slug=${slug}`;

    const response = await api.get(url);

    if (response.status !== 200) {
        console.error("Error fetching post content {%s}", response.data["detail"]);
        return [] as PostContentType[];
    }

    return response.data as PostContentType[];
}