import React, { useState, useEffect } from "react";

import { HeroBanner } from "../img";
import DailyVerses from "../components/LandingPage/DailyVerses";
import News from "../components/LandingPage/News";
import Post from "../components/ui/Post";
import { PostType } from "../types/Post";
import { getPosts } from "../services/postService";

const LandingPage: React.FC = () => {
  const [LastestPost, setPost] = useState<PostType>();

  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await getPosts();
      setPost(posts[0]);
    };

    fetchPosts();
  }, []);

  return (
    <main className="flex flex-col mb-12">
      <div
        id="Hero"
        className="relative lg:px-[11.25%] w-full h-[720px]"
      >
        <div className="absolute backgroundImageFilter" />
        <div className="md:hidden absolute backgroundImageFilterMobile" />
        <div className="absolute backgroundImageFilter2" />
        <img
          src={HeroBanner}
          alt="Hero Banner"
          className="absolute w-full h-full object-cover object-center -z-10 left-0 right-0"
          rel="preload"
          fetchPriority="high"
        />
        <div
        id="HeroWrapper"
        className="relative w-full h-full">
          <div
            id="HeroTitle"
            className="absolute md:hidden rounded-2xl bg-background bg-opacity-60 mx-auto left-0 right-0 w-max p-6 mt-8"
            aria-label="Baner"
          >
            <h1 className="semiboldheader2 sm:semiboldheader1 md:boldheader2 text-purple-500">
              Parafia <br className="xl:hidden"/><span className="text-purple-500">Ewangelicko-<br className="sm:hidden"/>Augsburska</span><br className="xl:hidden"/> Bielsko
            </h1>
          </div>
          <div
          id="HeroContent"
          className="hidden lg:flex flex-row  basis-1/4 justify-end p-6 mt-8">
            <div className="flex flex-col gap-y-2 items-center">
              <h3 className="text-purple-500 semiboldheader2 bg-background bg-opacity-60 p-3 rounded-md w-max">Najnowszy post</h3>
              {LastestPost? <Post post={LastestPost}/>:<Post />}
            </div>
          </div>
        </div>
      </div>
      <DailyVerses />
      <News />
    </main>
  );
};

export default LandingPage;
