import React, { useState, useEffect } from "react";

type Verse = {
  text: string;
  author: string;
};

const StaticVerses = `Pan jest ucieczką twoją. (Ps 91,9a)
Przed siwą głową wstaniesz i będziesz szanował osobę starca. (3 Mż 19,32a)
Nie będziecie kradli i nie będziecie się zapierali, i nie będziecie okłamywali jeden drugiego. (3 Mż 19,11)
Gdzie jest wiele snów, tam jest wiele słów i wiele marności. Lecz ty bój się Boga! (Kz 5,6)
Ciemność ustępuje, a światłość prawdziwa już świeci. (1 J 2,8b)
Wspominam Cię na łożu moim, rozmyślam o tobie podczas straży nocnych. (Ps 63,7)
Biada tym, którzy (...) obmyślają zbrodnię (...), gdyż jest to w ich mocy. (Mi 2,1)
Biada tym, którzy (...) polegają na koniach i ufność pokładają w wozach wojennych, że liczne, lecz nie patrzą na Świętego Izraelskiego i nie szukają Pana. (Iz 31,1)
Rozdzierajcie swoje serca, a nie swoje szaty i nawróćcie się do Pana, swojego Boga. (Jl 2,13a)
O to wydarzenia dawniejsze już się dokonały, a to, co ma nastać, zwiastuję; zanim zacznie kiełkować, opowiem ja wam. (Iz 42,9)
Pan rzekł do Gedeona: Pokój z tobą; nie bój się, nie umrzesz. I zbudował tam Gedeon ołtarz Panu, i nazwał go:„Pan jest pokojem” (Sdz 6,23-24a)
Pocieszajcie, pocieszajcie mój lud, mówi wasz Bóg! (Iz 40,1)
Cała ziemia niech będzie pełna chwały jego! (Ps 72,19b)
Biada tym, którzy przyłączają dom do domu, a rolę do roli dodają, tak że nie ma wolnego miejsca i tylko wy sami osiedliście w kraju. (Iz 5,8)
Strzeżcie się, aby wasze serce nie dało się zwieść o abyście nie odstąpili i nie służyli bogom innym, i nie oddawali im pokłonu. (5 Mż 11,16)
Twoim Panie, jest królestwo i Ty jesteś wyniesiony jako głowa nad wszystko. (1 Krn 29,11b)
Pan jest naszym sędzią, Pan naszym prawodawcą, Pan naszym królem: On nas wybawi. (Iz 33,22)
Wysławiajcie Pana albowiem jest dobry, albowiem łaska jego trwa na wiek! (Ps 106,1)
Rzekł Abram do Lota: Niechże nie będzie sporu między mną a tobą i między pasterzami moimi a twoimi, jesteśmy przecież braćmi. (1 Mż 13,8)
Panie! Boże mój! Zacząłeś ukazywać swemu słudze swoją wielkość i możną rękę swoją. (5 Mż 3,24b)
Bóg stworzył człowieka prawym, lecz oni uganiają się za wielu wymysłami. (Kz 7,29)
Ty wydobyłeś z przepaści moje życie, Panie Boże mój. (Jon 2,7b)
Błogosławić będę Pana w zgromadzeniach. (Ps 26,12b)
Pan (...) pośle anioła swego przed tobą. (1 Mż 24,7b)
Naaman rzekł: (...) sługa twój nie będzie już składał ofiar (...) innym bogom, jak tylko Panu. (2 Krl 5,17)
Bóg jest ucieczką i siłą naszą, pomocą w utrapieniach najpewniejszą. Przeto się nie boimy. Ps 46,2-3a)
Mojżesz rzekł: Jeżeli znalazłem łaskę w oczach twoich, Panie, racz pójść, Panie, w pośrodku nas. 2 Mż 34,9a)
Pan stróżem twoim, Pan cieniem twoim po prawicy twojej. Słońce nie będzie cię razić za dnia ani księżyc w nocy. (Ps 121,5-6)
Panie! (...) Uzdrów mnie, ożyw mnie! (Iz 38,16b)
Syn czci ojca (...) Jeżeli jestem ojcem, to gdzież jest moja cześć? (Ml 1,6a)
Boże, Panie mój, bądź za mną dla imienia swego, a że dobra jest łaska twoja, wybaw mnie! (1 Kor 10,23-34)
Bóg mówi: Wybawię was od wszystkich waszych nieczystości. (Ez 36,29a)
Gdy pomyślałem: Chwieje się noga moja - łaska twoja, Panie, wsparła mnie. (Ps 94,18)
Noe znalazł łaskę w oczach Pana. (1 Mż 6,8)
Stanie się w owym dniu, że narody będą szukać korzenia Isajego, który załopocze jako sztandar ludów; a miejsce jego pobytu będzie sławne. (Iz 11,10)
Oto ręka Pana nie jest tak krótka, aby nie mogła pomóc, a jego ucho nie jest tak przytępione, aby nie słyszeć. Lecz wasze winy są tym, co was odłączyło od waszego Boga. (Iz 59,1-2a)
Nas szum wielkich wód, nad potężne fale morskie, mocniejszy jest Pan na wysokości. (Ps 93,4)
Rozjaśnij swoje oblicze nad twoją spustoszoną świątynią, ze względu na siebie samego, o Panie! (Dn 9,17b)
Gdy głodnemu podasz swój chleb i zaspokoisz pragnienie strapionego, wtedy twoje światło wzejdzie w ciemności. (Iz 58,10)`.split("\n");

const DailyVerses: React.FC = () => {
  const [verses, setVerses] = useState<Verse[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVerses = async () => {
      setVerses([]);
    };

    fetchVerses();
    setLoading(false);
  }, []);

  return (
    <div className="flex flex-col w-full mb-12" aria-label="Z biblią na codzień">
      {/* <Spacer variant="BlueUp" /> */}
      <div
        id="DailyVercesContent"
        className="flex flex-col gap-y-6 px-8 py-4 md:px-[11.25%]"
        aria-label="daily-verses-content"
      >
        <div id="Title" className="flex flex-row" aria-label="Tytuł sekcji">
          <h2 className="text-purple-500 boldheader3 md:boldheader2">
            Z Biblią na co dzień
          </h2>
        </div>
        <div id="Verses" className="flex flex-col gap-y-5 mx-6" aria-label="Wersety">
          { loading &&             
          <>
              <div className="flex flex-col gap-y-4 max-w-3xl" aria-label="daily-verse-loading">
                <div className="flex flex-col gap-y-2">
                  <div className="bg-foreground h-3 opacity-60 w-[80%] rounded-md animate-pulse" />
                  <div className="bg-foreground h-3 opacity-60 w-[40%] rounded-md animate-pulse" />
                </div>
                <div className="bg-purple-300 h-6 opacity-80 w-[20%] rounded-md animate-pulse" />
              </div>
              <div className="flex flex-col gap-y-4 max-w-3xl" aria-label="daily-verse-loading">
                <div className="flex flex-col gap-y-2">
                  <div className="bg-foreground h-3 opacity-60 w-[50%] rounded-md animate-pulse" />
                </div>
                <div className="bg-purple-300 h-6 opacity-80 w-[30%] rounded-md animate-pulse" />
              </div>
              <div className="flex flex-col gap-y-4 max-w-3xl" aria-label="daily-verse-loading">
                <div className="flex flex-col gap-y-2">
                  <div className="bg-foreground h-3 opacity-60 w-[90%] rounded-md animate-pulse" />
                  <div className="bg-foreground h-3 opacity-60 w-[60%] rounded-md animate-pulse" />
                </div>
                <div className="bg-purple-300 h-6 opacity-80 w-[15%] rounded-md animate-pulse" />
              </div>
            </>
          }
          {(verses.length > 0&& !loading) ? (
            <div>{/* TODO: Fetch and display content here */}</div>
          ) : (
            <div className="text-foreground semiboldheader4">
              {StaticVerses[Math.floor(Math.random() * StaticVerses.length)]}
            </div>
          )}
        </div>
      </div>
      {/* <Spacer variant="BlueDown" /> */}
    </div>
  );
};

export default DailyVerses;
