import React, { useEffect, useState } from "react";

import { BannerOld } from "../../img";
import Post from "../ui/Post";
import { PostType } from "../../types/Post";
import { getPinnedPosts } from "../../services/postService";

const News: React.FC = () => {
  const [posts, setPosts] = useState<PostType[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await getPinnedPosts("LandingPage");

      setPosts(response);
    };
    fetchPosts();
  }, []);

  return (
    <div
      id="NewsContainer"
      className="relative w-full bg-cover bg-center"
      aria-label="Aktualności"
    >
      <div className="absolute backgroundImageFilterNews w-full" />
      <div className="absolute backgroundImageFilterLight w-full" />
      <img
        src={BannerOld}
        className="absolute w-full h-full bg-cover -z-10 object-cover left-0 right-0"
        loading="lazy"
        alt="Tło aktualności"
      />
      <div
        id="News"
        className="relative flex flex-col gap-y-6 object-cover w-full"
        aria-label="news-container"
      >
        <div
          id="NewsTitle"
          className="flex flex-row px-8 md:px-[11.25%] justify-end"
          aria-label="news-title"
        >
          <h2 className="text-purple-500 boldheader3 md:boldheader2">
            Aktualności
          </h2>
        </div>
        <div
          id="NewsContent"
          className="flex flex-wrap text-foreground w-full gap-y-6 md:px-[11.25%]"
          aria-label="news-content"
        >
          {posts.length === 0 &&
            [...Array(3)].map((_, index) => (
              <Post
                key={index}
                className="basis-[51%] lg:basis-[34%] 2xl:basis-[26%] grow-1 mx-auto"
              />
            ))}
          {posts.length > 0 &&
            posts &&
            posts.map((post, index) => (
              <Post
                post={post}
                key={index}
                className="basis-[51%] lg:basis-[34%] 2xl:basis-[26%] grow-1 mx-auto"
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default News;
