import { admin_api } from "./api";
import { ImageTypes } from "../types";
import { PostType, PostContentType, PostTagType, PageType } from "../types/Post";


interface getPostArgs {
    state: "published" | "draft" | "hidden";
}
export const getPosts = async (slug?: string, args?: getPostArgs): Promise<PostType[]> => {
    let url = `/posts/`;
    url = slug ? url + slug : url;
    Object.entries(args || {}).forEach(([key, value]) => {
        url = url + `?${key}=${value}`;
    });
    const response = await admin_api.get(url);

    if (response.status === 200) {
        if (slug) {
            return [response.data] as PostType[];
        }
        return response.data as PostType[];
    }

    console.log("Error when fetching posts: ", response);
    return [] as PostType[];
};
export const getPostContent = async (slug: string): Promise<PostContentType[]> => {
    const url = `/posts/content/?slug=${slug}`;
    const response = await admin_api.get(url);

    if (response.status === 200) {
        return response.data as PostContentType[];
    }

    console.log("Error when fetching post content: ", response);
    return [] as PostContentType[];
}
export const getTags = async (): Promise<PostTagType[]> => {
    const url = `/tags`;
    const response = await admin_api.get(url);

    if (response.status === 200) {
        return response.data as PostTagType[];
    }

    console.log("Error when fetching tags: ", response);
    return [] as PostTagType[];
};
export const createPost = async (formData: FormData): Promise<PostType | null> => {
    const url = `/posts/`;
    const response = await admin_api.post(url,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

    if (response.status === 201) {
        return response.data;
    }

    console.log("Error when creating post: ", response);
    return null;
};
export const patchPost = async (slug: string, formData: FormData): Promise<boolean> => {
    const url = `/posts/${slug}/`;
    const response = await admin_api.patch(url,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

    if (response.status === 200) {
        return true;
    }

    console.log("Error when patching post: ", response);
    return false;
}
export const patchPostState = async (slug: string, state: "published" | "draft" | "hidden"): Promise<boolean> => {
    const url = `/posts/${slug}/state/`;

    const response = await admin_api.patch(url, { "state": state });

    if (response.status === 200) {
        return true;
    }
    
    console.error("Error patching post state {%s}", response);
    return false;
}
export const getPinnedPosts = async (): Promise<PageType[]> => {
    const url = `/pages`;
    const response = await admin_api.get(url);

    if (response.status === 200) {
        return response.data as PageType[];
    }

    console.log("Error when fetching pages: ", response);
    return [] as PageType[];
}
export const patchPinnedPosts = async (formData: FormData): Promise<boolean> => {
    const url = `/pages/${formData.get('name')}/`;
    const response = await admin_api.patch(url,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    
    if (response.status === 200) {
        return true;
    }

    console.log("Error when patching pinned posts: ", response);
    return false;
}
export const patchPostTags = async (slug: string, tags: PostTagType[]): Promise<boolean> => {
    const url = `/posts/${slug}/`;
    const formData = new FormData();
    formData.append("tags", JSON.stringify(tags));
    
    const response = await admin_api.patch(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
});

    if (response.status === 200) {
        return true;
    }

    console.log("Error when patching post tags: ", response);
    return false;
}
export const deletePost = async (slug: string): Promise<boolean> => {
    const url = `/posts/${slug}/`;
    const response = await admin_api.delete(url);

    if (response.status === 204) {
        return true;
    }

    console.log("Error when deleting post: ", response);
    return false;
}
export const uploadImage = async (file: File, type?: ImageTypes): Promise<string> => {
    if (!file) {
        console.error("No file provided");
        return "";
    }

    let url = '/upload/';
    url = type ? url + `?fileType=${type}` : url;

    const formData = new FormData();
    formData.append('file', file);
    const response = await admin_api.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    if (response.status === 201) {
        return response.data.url as string;
    }

    console.log("Error when uploading image: ", response);
    return "";
}