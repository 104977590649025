import React from 'react';

import { GridRenderEditCellParams } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import ModeIcon from '@mui/icons-material/Mode';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ChipStateProps: Record<"published" | "draft" | "hidden", { label: string, color: "success" | "warning" | "error", icon?: JSX.Element }> = {
    published: {
        label: "Published",
        color: "success",
        icon: <CheckIcon />
    },
    draft: {
        label: "Draft",
        color: "warning",
        icon: <ModeIcon />
    },
    hidden: {
        label: "Hidden",
        color: "error",
        icon: <VisibilityOffIcon />
    }
}

function CustomStateRenderCell (props: GridRenderEditCellParams) {
    if (props.value === null) return null;

    return (
        <Chip
        {...ChipStateProps[props.value as "published" | "draft" | "hidden"]}
        variant='outlined'/>
    )
}

export { CustomStateRenderCell };