import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
} from "@mui/material";

import {
  getPinnedPosts,
  getPosts,
  patchPinnedPosts,
} from "../../../services/adminPostService";
import { PageType, PostType } from "../../../types/Post";
import Post from "../../../components/ui/Post";

import { setLoading } from "../../../constants/AdminEvents";
import PostsGrid from "../../../components/Admin/DataGrids/PostDataGrid";
import { PinPostGridAction } from "../../../components/Admin/DataGrids/Actions";

const PinPosts: React.FC = () => {
  const [pages, setPages] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<string>("");
  const [data, setData] = useState<PageType[]>([]);
  const [currentlyPinnedPosts, setCurrentlyPinnedPosts] = useState<PostType[]>(
    []
  );
  const [allPosts, setAllPosts] = useState<PostType[]>([]);

  // Fetch all posts that are not pinned and matching the correct state
  useEffect(() => {
    const fetchPosts = async (pinned_posts: PostType[]) => {
      const data = await getPosts(undefined, { state: "published" });

      if (data.length > 0) {
        const filteredPosts = data.filter(
          (post: PostType) =>
            !pinned_posts.some((pinnedPost) => pinnedPost.id === post.id) &&
            (currentPage === "LandingPage" ||
              post.tags.some((tag) => tag.name === currentPage))
        );
        setAllPosts(filteredPosts);
      }
    };

    const filteredPage = data.find(
      (page: PageType) => page.name === currentPage
    );
    if (filteredPage) {
      setCurrentlyPinnedPosts(filteredPage.pinned_posts);
      fetchPosts(filteredPage.pinned_posts);
    } else {
      setCurrentlyPinnedPosts([]);
    }
  }, [currentPage, data]);

  // Fetch all pages and set the initial state
  useEffect(() => {
    const fetchPages = async () => {
      const data = await getPinnedPosts();

      if (data.length > 0) {
        setData(data);
        setPages(data.map((page: PageType) => page.name));
        setCurrentPage(data[0].name);
      }
    };

    fetchPages();
  }, []);

  const handlePinPostResult = (value: boolean) => {
    const resultEvent = new CustomEvent("postsPinChange", {
      detail: { value },
  });
  window.dispatchEvent(resultEvent);
  }

  const handlePageChange = (event: SelectChangeEvent<string>) => {
    setCurrentPage(event.target.value as string);
  };

  const handlePinPost = (id: number, unpin?: boolean) => {
    setLoading(true);
    const post = allPosts.find((p) => p.id === id);

    const formData = new FormData();
    formData.append("name", currentPage);
    if (unpin) {
      formData.append(
        "pinned_posts",
        JSON.stringify(
          currentlyPinnedPosts.filter((p) => p.id !== id).map((p) => p.id)
        )
      );
    } else {
      formData.append(
        "pinned_posts",
        JSON.stringify([...currentlyPinnedPosts.map((p) => p.id), id])
      );
    }
    patchPinnedPosts(formData).then((result) => {
      if (result) {
        // update the state
        if (post) {
          const new_data = data.map((page: PageType) => {
            if (page.name === currentPage) {
              return {
                ...page,
                pinned_posts: unpin
                  ? currentlyPinnedPosts.filter((p) => p.id !== id)
                  : [...currentlyPinnedPosts, post],
              };
            }
            return page;
          });

          setData(new_data);
        }
        handlePinPostResult(true);
      } else {
        handlePinPostResult(false);
      }
      setLoading(false);
    });
  };

  // TODO: Admin loading spinner
  if (pages.length === 0 || data.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <h1 className="semiboldheader2">Pin Posts</h1>

      <FormControl margin="normal">
        <InputLabel id="page-select-label">Select a page</InputLabel>
        <Select
          labelId="page-select-label"
          id="page-select"
          input={<OutlinedInput id="select-page" label="Select a page" />}
          onChange={handlePageChange}
          value={currentPage}
          style={{ maxWidth: "15rem" }}
        >
          {pages.map((page, index) => (
            <MenuItem key={index} value={page}>
              {page}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="flex flex-col gap-y-4">
        <h2 className="semiboldheader3">Currently pinned posts</h2>
        <div className="flex flex-wrap mx-[5%] gap-y-3 justify-around">
          {currentlyPinnedPosts.length > 0 ? (
            currentlyPinnedPosts.map((post: PostType) => (
              <div
                key={post.id}
                className="relative basis-0 md:basis-1/4 xl:basis-1/3 max-w-[360px]"
              >
                <div className="absolute w-full h-full flex flex-col gap-y-2 justify-center items-center z-10 bg-background bg-opacity-0 opacity-0 hover:opacity-100 hover:bg-opacity-70 hover:border-2 hover:border-[#1976d2] rounded-2xl border-dashed">
                  <p className="text-foreground semiboldheader4">Unpin post?</p>
                  <Button
                    variant="contained"
                    onClick={() => handlePinPost(post.id, true)}
                  >
                    Yes
                  </Button>
                </div>
                <Post post={post} />
              </div>
            ))
          ) : (
            <p>No pinned posts available.</p>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-4">
        <h2 className="semiboldheader3">Pin more posts</h2>
        <PostsGrid
          data={allPosts}
          editable={false}
          actions={[
            {
              actionProps: handlePinPost,
              action: PinPostGridAction,
            },
          ]}
          initialState={{
            columns: {
              columnVisibilityModel: {
                "Updated at": false,
                isEvent: false,
                isFacebookPost: false,
              },
            },
          }}
        />
      </div>
    </Box>
  );
};

export default PinPosts;
