import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { isTokenExpired, useAuth } from "../../contexts/AuthContext";
import Login from "../../pages/admin/Login";

const PrivateRoute: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (location.pathname === "/admin/login") {
    return <Login />;
  }

  const token = localStorage.getItem("accessToken");
  if (!isAuthenticated || isTokenExpired(token)) {
    return <Navigate to="/admin/login" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
