type bank_info_type = {
    nip: string;
    region: string;
    accounts: {
        [key: string]: string;
    }
}

// Contact info
export const address = "Pl. Marcina Lutra 12";
export const zipcode = "43-300 Bielsko-Biała";

export const phone_parish = "+48 33 82 274 71"; // Telefon do parafii
export const phone_pastor = "+48 519 048 551"; // Telefon do proboszcza (Krzysztof Cienciała)

export const contact_email = "bielsko@luteranie.pl";

// Soar info
export const soar_address = "ul. Andrzeja Frycza Modrzewskiego 25";
export const soar_zipcode = "43-300 Bielsko-Biała";
export const soar_phone = "+48 33 812 61 64";

export const soar_bank_info: bank_info_type = {
    nip: "547-17-53-374",
    region: "0704 11 941",
    accounts: {
        "Konto główne": "33 1020 1390 0000 6902 0018 3913",
        "Konto darowizn": "52 1020 1390 0000 6302 0023 2074",
    }
};

export const soar_director = "Ilona Matuszek";
export const soar_director_deputy = "Jolanta Mentel";

// Bank info
export const bank_info: bank_info_type  = {
    nip: "547 02 93 189",
    region: "0704 11 941",
    accounts: {
        "KONTO GŁÓWNE": "PKO BP: 41 1020 1390 0000 6502 0143 4489",
        "KONTO ORGANOWE": "PKO BP: 72 1020 1390 0000 6302 0427 6614",
        "KONTO STARY CMENTARZ": "PKO BP: 45 1020 1390 0000 6802 0497 7098",
        "KONTO NOWE CMENTARZE": "PKO BP: 35 1020 1390 0000 6902 0550 8595",
        "KONTO DZIAŁALNOŚĆ GOSPODARCZA": "PKO BP: 96 1020 1390 0000 6702 0580 1602"
    }
};

// Social media
export const facebook_link = "https://www.facebook.com/profile.php?id=100079543970479";
export const youtube_link = "https://www.youtube.com/@parafiaewangelicko-augsbur5230";
export const graduale_facebook_link = "https://www.facebook.com/chorgraduale/";